import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import {Button, NavBar, Result} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./Result.module.css";
import {tradePay} from "./pay";

const ResultIndex = (props) => {
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [trade, setTrade] = useState({});

    useEffect(() => {
        document.title = '我德权益' // 默认标题

        handleLoadTrade();
        //eslint-disable-next-line
    }, []);

    useActivate(() => {
    });

    useUnactivate(() => {
    });

    const handleLoadTrade = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/trade/consumer/mobile/v1/find",
            data: {
                tradeId: props.match.params.tradeId,
            },
            success: (response) => {
                setTrade(response.data);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {
                    },
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                预约结果
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} backgroundColor={"#ffffff"}>
                <div className={Style.empty}></div>
                {
                    (trade.tradeStatus === "WAITING_CONFIRM" || trade.tradeStatus === "WAITING_CHECK"|| trade.tradeStatus === "INCOMPLETE"|| trade.tradeStatus === "INCOMPLETE_CONFIRM") &&
                    (
                        <Result
                            status="success"
                            title="订单提交成功"
                            description={
                                <div style={{textAlign: "left"}}>
                                    <div>1、请耐心等待酒店确认，确认结果请以酒店确认短信为准。</div>
                                    <div>
                                        2、<strong>请在到达酒店后出示上述确认短信或订单码作为用餐凭证，若无法出示，则酒店无法提供相应服务与优惠。</strong>
                                    </div>
                                </div>
                            }
                        />
                    )
                }
                {
                    trade.tradeStatus === "CANCEL" &&
                    (
                        <Result
                            status="info"
                            title="订单已取消"
                            description={
                                <div style={{textAlign: "left"}}>
                                    <div>1、您的订单已取消，取消结果请以酒店通知短信为准。</div>
                                    <div>
                                        2、<strong>已取消的订单，到达酒店后出示确认短信或订单码作为用餐凭证，酒店无法提供相应服务与优惠。</strong>
                                    </div>
                                </div>
                            }
                        />
                    )
                }
                {
                    trade.tradeStatus === "COMPLETE" &&
                    (
                        <Result
                            status="info"
                            title="订单已完成"
                            description={
                                <div style={{textAlign: "left"}}>
                                    <div>1、您的订单已完成。</div>
                                </div>
                            }
                        />
                    )
                }
                {
                    trade.tradeStatus === "REJECT" &&
                    (
                        <Result
                            status="info"
                            title="订单已决绝"
                            description={
                                <div style={{textAlign: "left"}}>
                                    <div>1、您的订单已拒绝，结果请以酒店通知短信为准。</div>
                                </div>
                            }
                        />
                    )
                }
                {
                    trade.tradeStatus === "WAITING_PAYMENT" &&
                    (
                        <Result
                            status="success"
                            title="订单提交成功,请支付"
                            description={
                                <div style={{textAlign: "left"}}>
                                    <div>1、请尽快支付订单, 支付完成后需等待酒店确认，确认结果请以酒店确认短信为准。</div>
                                    <div>
                                        2、<strong>请在到达酒店后出示上述确认短信或订单码作为用餐凭证，若无法出示，则酒店无法提供相应服务与优惠。</strong>
                                    </div>
                                </div>
                            }
                        />
                    )
                }
                {/*{
                    (trade.paymentStatus === undefined || trade.paymentStatus === null || trade.paymentStatus === "null") &&
                    <Result
                        status="error"
                        title="订单提交失败"
                        description={
                            <div style={{textAlign: "left"}}>
                                <div>1、可能遇到网络问题，请稍后再来操作</div>
                                <div>2、请确认您的权益有效期或剩余次数，或者与我们管理人员联系</div>
                                <div>3、联系电话：400-005-4577</div>
                                <div>4、电话服务时间：周一至周日AM9:30～12:00 PM12:30～17:30</div>
                            </div>
                        }
                    />
                }*/}
                <div className={Style.button}>
                    {trade.tradeStatus === "WAITING_PAYMENT"?(<Button style={{marginBottom:"15px"}} color="success" block onClick={()=>{tradePay(props,trade.tradeId,handleLoadTrade)}}>使用微信支付</Button>):null}

                    <Button
                        color={trade.tradeStatus==="WAITING_PAYMENT"?"default":"primary"}
                        block
                        onClick={() => {
                            switch (props.match.path) {
                                case "/trade/activity/result/:activityId/:tradeId":
                                    ChuangShiOS.Util.redirectTo(props, {
                                        url: "/activity/index/" + props.match.params.activityId,
                                    });

                                    break;
                                case "/trade/benefit/result/:tradeId":
                                    if (trade.tradeStatus === "WAITING_PAYMENT") {
                                        ChuangShiOS.Util.redirectTo(props, {
                                            url: "/trade/index",
                                        });
                                    } else {
                                        ChuangShiOS.Util.redirectTo(props, {
                                            url: "/benefit/index",
                                        });
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }}
                    >
                        { props.match.path === "/trade/benefit/result/:tradeId" ? "查看订单" : "返回预订首页"}
                    </Button>
                </div>
            </ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

ResultIndex.propTypes = {};

ResultIndex.defaultProps = {};

export default withRouter(ResultIndex);
