import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import {NavBar} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

const Forget = (props) => {
    useEffect(() => {
        //eslint-disable-next-line
    }, []);

    useActivate(() => {});

    useUnactivate(() => {});

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eeeeee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                页面异常
            </NavBar>
            <ChuangShiOS.Content isLaunch={true} isEmpty={false} isError={true}></ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

Forget.propTypes = {};

Forget.defaultProps = {};

export default withRouter(Forget);
