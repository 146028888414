import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import {NavBar, Form, Input, Button} from "antd-mobile";
import Countdown from "react-countdown";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const Register = (props) => {
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isCountdown, setIsCountdown] = useState(false);
    const [memberPhone, setMemberPhone] = useState("");

    useEffect(() => {
        setIsLaunch(true);

        //eslint-disable-next-line
    }, []);

    useActivate(() => {});

    useUnactivate(() => {});

    const handleSubmit = (data) => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.toastLoad();

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/consumer/mobile/v1/register",
            data: data,
            success: (response) => {
                ChuangShiOS.Util.setToken(response.data.token);

                ChuangShiOS.Notification.emit("member_find_succes", {});

                ChuangShiOS.Util.toastSuccess({
                    message: response.message,
                    complete: () => {},
                });

                setTimeout(() => {
                    ChuangShiOS.Util.validateLoginCallback(props, {});
                }, 2000);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    const handleClickLogin = () => {
        ChuangShiOS.Util.redirectTo(props, {
            url: "/login/index",
        });
    };

    const handleClickForget = () => {
        ChuangShiOS.Util.redirectTo(props, {
            url: "/forget/index",
        });
    };

    const handleSendSms = () => {
        if (memberPhone == "") {
            ChuangShiOS.Util.toastWarning({
                message: "手机号码不能为空",
            });

            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        setCountdown(Date.now() + 60000);

        setTimeout(() => {
            setIsCountdown(true);
        }, 500);

        ChuangShiOS.Util.toastLoad();

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/consumer/mobile/v1/register/captcha/code",
            data: {
                memberPhone: memberPhone,
            },
            success: (response) => {
                ChuangShiOS.Util.toastHide();
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    const handleSopCountdown = () => {
        setIsCountdown(false);
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                会员注册
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} loadingColor={"#c26b60"}>
                <div className={Style.empty}></div>
                <Form
                    layout="horizontal"
                    footer={
                        <Button block type="submit" color="primary">
                            注册
                        </Button>
                    }
                    onFinish={handleSubmit}
                >
                    <Form.Item name="memberPhone" label="手机号码" rules={[{required: true, message: "请输入手机号码"}]}>
                        <Input
                            type="number"
                            onChange={(event) => {
                                setMemberPhone(event);
                            }}
                            placeholder="请输入手机号码"
                        />
                    </Form.Item>
                    <Form.Item name="captchaCode" label="验证码" rules={[{required: true, message: "请输入验证码"}]}>
                        <div style={{display: "flex"}}>
                            <div style={{flex: 1}}>
                                <Input type="number" placeholder="请输入验证码" />
                            </div>
                            <div className={Style.captcha}>
                                <Button
                                    block
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        handleSendSms();
                                    }}
                                >
                                    {isCountdown ? (
                                        <Countdown
                                            date={countdown}
                                            renderer={({hours, minutes, seconds, completed}) => {
                                                return <span>{seconds}秒</span>;
                                            }}
                                            onComplete={handleSopCountdown}
                                        ></Countdown>
                                    ) : (
                                        "获取验证码"
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item name="memberPassword" label="登录密码" rules={[{required: true, message: "请输入登录密码"}]}>
                        <Input type="password" placeholder="请输入登录密码" />
                    </Form.Item>
                </Form>
                <div className={Style.action}>
                    <div className={Style.action_item} onClick={handleClickLogin}>
                        手机号码登录
                    </div>
                    <div className={Style.action_item} onClick={handleClickForget}>
                        忘记密码？
                    </div>
                </div>
            </ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

Register.propTypes = {};

Register.defaultProps = {};

export default withRouter(Register);
