import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import {Button, NavBar, Result} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";
import Application from "../../common/Application";
import Style from "./ResultEfund.module.css";

/* 易方达专用预订结果页面 */

const ResultIndex = (props) => {
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [trade, setTrade] = useState({});

    useEffect(() => {
        // 设置易方达页面title
        let documentTitle =  localStorage.getItem("page_title_EFUND")
        if (documentTitle){
            document.title = documentTitle
        }else {
            document.title = '我德权益' // 默认标题
        }

        handleLoadTrade();
        //eslint-disable-next-line
    }, []);

    useActivate(() => {
    });

    useUnactivate(() => {
    });

    const handleLoadTrade = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/trade/consumer/mobile/v1/find",
            data: {
                tradeId: props.match.params.tradeId,
            },
            success: (response) => {
                setTrade(response.data);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {
                    },
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    /*
    易方达返回按钮规则
    根据回调接口的参数source来判断是否显示“查看订单详情”按钮，并跳转对应的中间页面。
    source= Eservice-xcx 跳转小程序中间页面地址（查看订单详情）
    source= Eservice-H5跳转小程序中间页面地址（查看订单详情）
    source= wx 跳转H5中间页地址（查看订单详情）
    source= app跳转H5中间页地址（查看订单详情）
    source= pc 保持原按钮（返回预定首页）
    source= crm 保持原按钮（返回预定首页）
    */
    const efundTradeDetailRedirect = ()=>{
        const source = localStorage.getItem("source")
        // 跳转小程序
        if (source==='Eservice-xcx' || source ==='Eservice-H5'){
            window.location = trade.middlePageWx
            return
        }
        // 跳转h5
        if (source==='wx' || source ==='app'){
            window.location = trade.middlePageH5
            return
        }
        ChuangShiOS.Util.redirectTo(props, {
            url: "/activity/index/" + props.match.params.activityId,
        });
    }

    return (
        <ChuangShiOS.Page>
            <NavBar back="返回" style={{"--border-bottom": "1px #eee solid"}} onBack={() => {ChuangShiOS.Util.navigateBack(props);}}>
                预约结果
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} backgroundColor={"#ffffff"}>
                <div className={Style.empty}></div>
                <Result
                    status="success"
                    title="申请已提交"
                    description={
                        <div style={{textAlign: "left"}}>
                            <p>1、申请结果请留意短信通知，以短信提示内容为准。</p>
                            <p>2、订单详情可点击易之道投资者俱乐部专区首页“礼券明细”对应记录进行查看。</p>
                            <p>
                                3、<strong>请在到达酒店后出示确认短信或订单码作为用餐凭证，若无法出示或订单已取消，则酒店无法提供相应服务与优惠。</strong>
                            </p>
                        </div>
                    }
                />
                <div className={Style.button}>
                    <Button color={"primary"} block onClick={() => efundTradeDetailRedirect()}>
                        返回首页
                    </Button>
                </div>
            </ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

ResultIndex.propTypes = {};

ResultIndex.defaultProps = {};

export default withRouter(ResultIndex);
