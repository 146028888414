import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";

import ShopActivityIndex from "./ActivityIndex";
import ShopBenefitIndex from "./BenefitIndex";
import ShopDetail from "./Detail";

const data = [
    <Route
        exact
        key="ShopActivityIndex"
        path="/shop/activity/index/:activityId/:activityCategoryId"
        render={(props) => {
            return (
                <KeepAlive
                    name={"/shop/activity/index/" + props.match.params.activityId + "/" + props.match.params.activityCategoryId}
                    key={props.match.params.activityId + "/" + props.match.params.activityCategoryId}
                >
                    <ShopActivityIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ShopBenefitIndex"
        path="/shop/benefit/index/:memberBenefitId/:benefitCategoryId"
        render={(props) => {
            return (
                <KeepAlive
                    name={"/shop/benefit/index/" + props.match.params.memberBenefitId + "/" + props.match.params.benefitCategoryId}
                    key={props.match.params.memberBenefitId + "/" + props.match.params.benefitCategoryId}
                >
                    <ShopBenefitIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ShopActivityDetail"
        path="/shop/activity/detail/:activityId/:activityCategoryId/:shopId"
        render={(props) => {
            return (
                <KeepAlive
                    name={
                        "/shop/activity/detail/" +
                        props.match.params.activityId +
                        "/" +
                        props.match.params.activityCategoryId +
                        "/" +
                        props.match.params.shopId
                    }
                    key={props.match.params.activityId + "/" + props.match.params.activityCategoryId + "/" + props.match.params.shopId}
                >
                    <ShopDetail {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ShopBenefitDetail"
        path="/shop/benefit/detail/:memberBenefitId/:benefitCategoryId/:shopId"
        render={(props) => {
            return (
                <KeepAlive
                    name={
                        "/shop/activity/detail/" +
                        props.match.params.memberBenefitId +
                        "/" +
                        props.match.params.benefitCategoryId +
                        "/" +
                        props.match.params.shopId
                    }
                    key={props.match.params.memberBenefitId + "/" + props.match.params.benefitCategoryId + "/" + props.match.params.shopId}
                >
                    <ShopDetail {...props} />
                </KeepAlive>
            );
        }}
    />,
];

export default data;
