import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import {Button, Dialog, List, NavBar, Space, Tabs} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Footer from "../../component/footer/Index";

import Style from "./Index.module.css";

import MemberImage from "../my/image/memberImage.png";

import {tradePay} from "./pay";

const TradeIndex = (props) => {
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [tradeList, setTradeList] = useState([]);
    const [tradeStatus, setTradeStatus] = useState("");

    useEffect(() => {
        // 获取微信openid
        //loadOpenid();

        let token = ChuangShiOS.Util.getToken();
        if (token) {
            setIsLogin(true);
            handleLoadTrade(tradeStatus);
        } else {
            setIsLaunch(true);
            setIsLogin(false);
        }

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        // 获取微信openid
        //loadOpenid();

        let token = ChuangShiOS.Util.getToken();
        if (token) {
            setIsLogin(true);
            console.log("propos2 {}", props)
            handleLoadTrade(tradeStatus);
        } else {
            setIsLogin(false);
        }
    });

    useUnactivate(() => {
    });

    const handleLoadTrade = (tradeStatus) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setTradeStatus(tradeStatus);

        if (isLaunch) {
            ChuangShiOS.Util.toastLoad();
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/trade/consumer/mobile/v1/list",
            data: {
                tradeStatus: tradeStatus,
            },
            success: (response) => {
                if (isLaunch) {
                    ChuangShiOS.Util.toastHide();
                }

                setIsEmpty(response.data.length == 0);
                setTradeList(response.data);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {
                    },
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleCancelTrade = (tradeId) => {
        Dialog.confirm({
            content: "是否取消订单",
            onConfirm: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                if (isLaunch) {
                    ChuangShiOS.Util.toastLoad();
                }
                let params = {
                    tradeId: tradeId,
                };
                params.source = localStorage.getItem("source");
                params.operateUser = localStorage.getItem("operateUser");

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/trade/consumer/mobile/v1/cancel",
                    data: params,
                    success: (response) => {
                        request.current = false;

                        ChuangShiOS.Util.toastHide();

                        ChuangShiOS.Util.toastSuccess({
                            message: response.message,
                            complete: () => {
                            },
                        });

                        handleLoadTrade(tradeStatus);
                    },
                    error: (response) => {
                        request.current = false;

                        ChuangShiOS.Util.toastError({
                            message: response.message,
                            complete: () => {
                            },
                        });
                    },
                    complete: (response) => {
                    },
                });
            },
        });
    };


    const handlePayTrade = (tradeId) => {
        tradePay(props,tradeId,()=>{handleLoadTrade(tradeStatus);})
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back={null}
                style={
                    isLogin
                        ? {
                            "--border-bottom": "1px #eee solid",
                        }
                        : {}
                }
            >
                订单
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                {isLogin ? (
                    <div className={Style.page}>
                        <Tabs
                            style={{
                                "--title-font-size": "3.73333vw",
                            }}
                            className={Style.tabs}
                            onChange={(value) => {
                                handleLoadTrade(value);
                            }}
                        >
                            <Tabs.Tab title="全部" key=""></Tabs.Tab>
                            <Tabs.Tab title="待支付" key="WAITING_PAYMENT"></Tabs.Tab>
                            <Tabs.Tab title="待确认" key="WAITING_CONFIRM"></Tabs.Tab>
                            <Tabs.Tab title="待使用" key="WAITING_CHECK"></Tabs.Tab>
                            <Tabs.Tab title="已完成" key="COMPLETE"></Tabs.Tab>
                        </Tabs>
                        <ChuangShiOS.Content isLaunch={true} isEmpty={isEmpty}>
                            {tradeList.map((trade, tradeIndex) => {
                                return (
                                    <React.Fragment key={tradeIndex}>
                                        <div className={Style.empty}></div>
                                        <List>
                                            <List.Item>
                                                <div className={Style.row}>
                                                    <div className={Style.col}>
                                                    {'WAITING_CHECK,COMPLETE,INCOMPLETE,INCOMPLETE_CONFIRM'.indexOf(trade.tradeStatus)===-1?null:(
                                                        <React.Fragment>
                                                            <div>核销码{trade.supplierPlatformLabel?`(`+trade.supplierPlatformLabel+`)`:``} :</div>
                                                            <div className={Style.trade_no}>{trade.checkNo}</div>
                                                        </React.Fragment>
                                                        )}
                                                    </div>
                                                    <div className={Style.trade_status}>{trade.tradeStatusValue}</div>
                                                </div>
                                                { trade.tradeNo !== trade.checkNo && trade.checkNo ?(
                                                    <div className={Style.col}>
                                                        <div>核销码{trade.supplierPlatformLabel?`(`+trade.supplierPlatformLabel+`)`:``} :</div>
                                                        <div className={Style.trade_no}>{trade.checkNo}</div>
                                                    </div>
                                                ):null  }

                                            </List.Item>
                                            <List.Item
                                                // onClick={() => {
                                                //     handleClickTrade(trade.tradeId);
                                                // }}
                                            >
                                                <div className={Style.goods}>
                                                    <div className={Style.goods_image} style={{backgroundImage: "url(" + Application.imagePreviewHost + trade.goodsImage + ")"}}></div>
                                                    <div className={Style.goods_main}>
                                                        <div className={Style.goods_name}>商品名称: {trade.goodsSkuName}</div>
                                                        <div className={Style.goods_text}>酒店名称: {trade.merchantName}</div>
                                                        <div className={Style.goods_text}>门店名称: {trade.shopName}</div>
                                                        {!trade.tradePoints?(<div className={Style.goods_text}>订单价格: <span className={Style.goods_price}> ¥{trade.tradeAmount}</span></div>):null}
                                                        {trade.tradePoints>0?(<div className={Style.goods_text}>订单价格: <span className={Style.goods_price}> {trade.tradePoints}积点</span></div>):null}
                                                        {trade.paymentAmount>0&&trade.paymentAmount!==trade.tradeAmount?(<div className={Style.goods_text}>实付金额:<span className={Style.goods_price}> ¥{trade.paymentAmount}</span></div>):null}
                                                        <div className={Style.goods_text}>预定姓名: {trade.reserveName}</div>
                                                        <div className={Style.goods_text}>预定手机: {trade.reservePhone}</div>
                                                        <div className={Style.goods_text}>出席人数: {trade.reserveQuantity}</div>
                                                        <div className={Style.goods_text}>出席时间: {trade.reserveTime}</div>
                                                        {trade.supplier==='WO_DE'?(<div className={Style.goods_text}><span style={{color:'#ed902d'}}>*本订单需使用我德券码系统核销</span></div>):null}
                                                    </div>
                                                </div>
                                            </List.Item>
                                            {trade.tradeStatus === "WAITING_CONFIRM" ||
                                            trade.tradeStatus === "WAITING_CHECK" ? (
                                                <List.Item>
                                                    <div className={Style.row}>
                                                        <div className={Style.col}>{trade.createTime}</div>
                                                        <Button
                                                            theme="primary"
                                                            size="sm"
                                                            ghost
                                                            onClick={() => {
                                                                handleCancelTrade(trade.tradeId);
                                                            }}
                                                        >
                                                            取消订单
                                                        </Button>
                                                    </div>
                                                </List.Item>
                                            ) : null}

                                            {trade.tradeStatus === "WAITING_PAYMENT" ? (
                                                <List.Item>
                                                    <div className={Style.row}>
                                                        <div className={Style.col}>{trade.createTime}</div>
                                                        <Space wrap>
                                                            <Button
                                                                theme="primary"
                                                                size="sm"
                                                                ghost
                                                                onClick={() => {
                                                                    handleCancelTrade(trade.tradeId);
                                                                }}
                                                            >
                                                                取消订单
                                                            </Button>
                                                            <Button
                                                                theme="primary"
                                                                size="sm"
                                                                ghost
                                                                onClick={() => {
                                                                    handlePayTrade(trade.tradeId);
                                                                }}
                                                            >
                                                                支付订单
                                                            </Button>
                                                        </Space>
                                                    </div>
                                                </List.Item>
                                            ) : null}
                                        </List>
                                    </React.Fragment>
                                );
                            })}
                            <div className={Style.empty}></div>
                        </ChuangShiOS.Content>
                    </div>
                ) : (
                    <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                        <List>
                            <List.Item
                                hasArrow
                                prefix={<img alt="" src={MemberImage} className={Style.profile_image}/>}
                                title={
                                    <React.Fragment>
                                        <div className={Style.login_title}>登录/注册</div>
                                        <div className={Style.login_tips}>登录后更多权益</div>
                                    </React.Fragment>
                                }
                                description=""
                                onClick={() => {
                                    ChuangShiOS.Util.navigateTo(props, {
                                        url: "/login/index",
                                    });
                                }}
                            ></List.Item>
                        </List>
                        <div className={Style.empty}></div>
                    </ChuangShiOS.Content>
                )}
            </ChuangShiOS.Content>
            <Footer index={1} activityId={props.match.params.activityId}></Footer>
        </ChuangShiOS.Page>
    );
};

TradeIndex.propTypes = {};

TradeIndex.defaultProps = {};

export default withRouter(TradeIndex);
