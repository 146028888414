import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate, useAliveController} from "react-activation";
import {NavBar, Swiper} from "antd-mobile";
import {RightOutline} from "antd-mobile-icons";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const BenefitDetail = (props) => {
    const {drop} = useAliveController();
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isAll, setIsAll] = useState(false);
    const [useCount, setUseCount] = useState(0);
    const [benefit, setBenefit] = useState({
        benefitBannerList: [],
        benefitRuleList: [],
        benefitCategoryList: [],
    });

    useEffect(() => {
        drop(new RegExp("/shop/benefit/index/*"));

        handleLoadBenefit();

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        drop(new RegExp("/shop/benefit/index/*"));

        handleLoadBenefit();
    });

    useUnactivate(() => {});

    const handleLoadBenefit = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/benefit/consumer/mobile/v1/find",
            data: {
                memberBenefitId: props.match.params.memberBenefitId,
            },
            success: (response) => {
                setBenefit(response.data);

                for (let i = 0; i < response.data.benefitRuleList.length; i++) {
                    if (response.data.benefitRuleList[i].benefitRuleType == "ALL") {
                        setIsAll(true);

                        break;
                    }
                }

                let useCount = 0;
                for (let i = 0; i < response.data.benefitCategoryList.length; i++) {
                    useCount += response.data.benefitCategoryList[i].memberBenefitCategoryQuantity;
                }
                setUseCount(useCount);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleClickBanner = (url) => {
        if (url) {
            ChuangShiOS.Util.navigateTo(props, {
                url: url,
            });
        }
    };

    const handleClickShop = (benefitCategoryId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/shop/benefit/index/" + props.match.params.memberBenefitId + "/" + benefitCategoryId,
        });
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                享乐生活套餐
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                <div className={Style.empty}></div>
                <div className={Style.title}>最新推荐</div>
                <div className={Style.banner}>
                    {benefit.benefitBannerList.length > 0 ? (
                        <Swiper autoplay loop>
                            {benefit.benefitBannerList.map((benefitBanner, benefitBannerIndex) => {
                                return (
                                    <Swiper.Item key={benefitBannerIndex}>
                                        <div
                                            key={benefitBannerIndex}
                                            className={Style.banner_image}
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    Application.imagePreviewHost +
                                                    ChuangShiOS.Util.scaleImage(benefitBanner.benefitBannerImage, 750) +
                                                    ")",
                                            }}
                                            onClick={() => {
                                                handleClickBanner(benefitBanner.benefitBannerUrl);
                                            }}
                                        ></div>
                                    </Swiper.Item>
                                );
                            })}
                        </Swiper>
                    ) : null}
                </div>
                {benefit.benefitRuleList.length > 0 ? (
                    <React.Fragment>
                        <div className={Style.empty}></div>
                        <div className={Style.title}>权益规则</div>
                        <div className={Style.card}>
                            {benefit.benefitRuleList.map((benefitRule, benefitRuleIndex) => {
                                return (
                                    <div key={benefitRuleIndex} className={Style.item}>
                                        {benefitRule.benefitRuleType == "DAY" ? "每天限制" + benefitRule.benefitRuleQuantity + "次" : null}
                                        {benefitRule.benefitRuleType == "WEEK" ? "每周限制" + benefitRule.benefitRuleQuantity + "次" : null}
                                        {benefitRule.benefitRuleType == "MONTH" ? "每月限制" + benefitRule.benefitRuleQuantity + "次" : null}
                                        {benefitRule.benefitRuleType == "SEASON" ? "每季限制" + benefitRule.benefitRuleQuantity + "次" : null}
                                        {benefitRule.benefitRuleType == "YEAR" ? "每年限制" + benefitRule.benefitRuleQuantity + "次" : null}
                                        {benefitRule.benefitRuleType == "ALL"
                                            ? "总权益次数: " +
                                              benefitRule.benefitRuleQuantity +
                                              ", 剩余权益次数: " +
                                              (benefitRule.benefitRuleQuantity > useCount ? benefitRule.benefitRuleQuantity - useCount : 0)
                                            : null}
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                ) : null}
                {benefit.benefitCategoryList.map((benefitCategory, benefitCategoryIndex) => {
                    return (
                        <div
                            key={benefitCategoryIndex}
                            className={Style.card}
                            onClick={() => {
                                handleClickShop(benefitCategory.benefitCategoryId);
                            }}
                        >
                            <div className={Style.item}>
                                <div
                                    className={Style.image}
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            Application.imagePreviewHost +
                                            ChuangShiOS.Util.scaleImage(benefitCategory.benefitCategoryImage, 120) +
                                            ")",
                                    }}
                                ></div>
                                <div className={Style.text}>
                                    <div className={Style.title}>{benefitCategory.benefitCategoryName}</div>
                                    {benefitCategory.benefitCategoryRemark ? (
                                        <div className={Style.remark}>{benefitCategory.benefitCategoryRemark}</div>
                                    ) : null}
                                    {isAll ? null : (
                                        <div className={Style.remark}>
                                            权益次数: {benefitCategory.benefitCategoryQuantity}次，剩余次数:{" "}
                                            {benefitCategory.benefitCategoryQuantity >= benefitCategory.memberBenefitCategoryQuantity
                                                ? benefitCategory.benefitCategoryQuantity - benefitCategory.memberBenefitCategoryQuantity
                                                : 0}
                                            次
                                        </div>
                                    )}
                                </div>
                                <div className={Style.icon}>
                                    <RightOutline />
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className={Style.empty}></div>
            </ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

BenefitDetail.propTypes = {};

BenefitDetail.defaultProps = {};

export default withRouter(BenefitDetail);
