import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import ChuangShiOS from "chuangshi-os-mobile-component";

const AuthIndex = (props) => {
    useEffect(() => {
        let token = ChuangShiOS.Util.getParameter("token");
        let url = ChuangShiOS.Util.getParameter("url");
        let custNo = ChuangShiOS.Util.getParameter("custNo");
        let actId = ChuangShiOS.Util.getParameter("actId");
        let svcProjectId = ChuangShiOS.Util.getParameter("svcProjectId");
        let source = ChuangShiOS.Util.getParameter("source");
        let operateUser = ChuangShiOS.Util.getParameter("operateUser");
        let activityMaxQuantity = ChuangShiOS.Util.getParameter("activityMaxQuantity");
        let custId = ChuangShiOS.Util.getParameter("custId");
        let cid = ChuangShiOS.Util.getParameter("cid");
        let projectId = ChuangShiOS.Util.getParameter("projectId");
        let mobile = ChuangShiOS.Util.getParameter("mobile");
        let openid = ChuangShiOS.Util.getParameter("openid");

        if (token) {
            ChuangShiOS.Util.setToken(token);
            localStorage.setItem("custNo", custNo);
            localStorage.setItem("actId", actId);
            localStorage.setItem("svcProjectId", svcProjectId);
            localStorage.setItem("source", source);
            localStorage.setItem("operateUser", operateUser);
            localStorage.setItem("activityMaxQuantity", activityMaxQuantity);
            localStorage.setItem("custId", custId);
            localStorage.setItem("cid", cid);
            localStorage.setItem("projectId", projectId);
            localStorage.setItem("mobile", mobile);
            // localStorage.setItem("openid", openid);
            sessionStorage.setItem("openid", openid);

            ChuangShiOS.Util.redirectTo(props, {
                url: url,
            });
        }

        //eslint-disable-next-line
    }, []);

    useActivate(() => {});

    useUnactivate(() => {});

    return (
        <ChuangShiOS.Page>
            <ChuangShiOS.Content isLaunch={false}></ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

AuthIndex.propTypes = {};

AuthIndex.defaultProps = {};

export default withRouter(AuthIndex);
