import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";

import BenefitIndex from "./Index";
import BenefitDetail from "./Detail";
import BenefitExchange from "./Exchange";

const data = [
    <Route
        exact
        key="BenefitIndex"
        path="/benefit/index"
        render={(props) => {
            return (
                <KeepAlive name="/benefit/index">
                    <BenefitIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="BenefitDetail"
        path="/benefit/detail/:memberBenefitId"
        render={(props) => {
            return (
                <KeepAlive name={"/benefit/detail/" + props.match.params.memberBenefitId} key={props.match.params.memberBenefitId}>
                    <BenefitDetail {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="BenefitExchange"
        path="/benefit/exchange"
        render={(props) => {
            return <BenefitExchange {...props} />;
        }}
    />,
];

export default data;
