import React from "react";
import {Route} from "react-router-dom";

import ErrorIndex from "./Index";

const data = [
    <Route
        exact
        key="ErrorIndex"
        path="/error/index"
        render={(props) => {
            return <ErrorIndex {...props} />;
        }}
    />,
];

export default data;
