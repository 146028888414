import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate, useAliveController} from "react-activation";
import {NavBar, Tabs, List, InfiniteScroll, CascadePicker} from "antd-mobile";
import {EnvironmentOutline} from "antd-mobile-icons";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./ActivityIndex.module.css";

const ShopActivityIndex = (props) => {
    const request = useRef(false);
    const {drop} = useAliveController();
    const [isLaunch, setIsLaunch] = useState(false);
    const [activityCategoryId, setActivityCategoryId] = useState("");
    const [activityCategoryIndex, setActivityCategoryIndex] = useState(-1);
    const [activityCategoryList, setActivityCategoryList] = useState([]);
    const [shopList, setShopList] = useState([]);
    const [loadList, setLoadList] = useState([]);
    const [isCity, setIsCity] = useState(false);
    const [provinceId, setProvinceId] = useState("");
    const [cityId, setCityId] = useState("");
    const [cityName, setCityName] = useState("全国");
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        drop(new RegExp("/shop/activity/detail"));

        setActivityCategoryId(props.match.params.activityCategoryId);

        handleLoadCity();

        handleLoadActivityCategory();

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        drop(new RegExp("/shop/activity/detail"));
    });

    useUnactivate(() => {});

    const handleLoadCity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/consumer/mobile/v1/list",
            data: {},
            success: (response) => {
                let cityList = response.data;
                cityList.unshift({
                    label: "全国",
                    children: [
                        {
                            label: "全国",
                            value: "",
                        },
                    ],
                    value: "",
                });
                setCityList(cityList);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {},
        });
    };

    const handleLoadActivityCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/consumer/mobile/v1/category/list",
            data: {
                activityId: props.match.params.activityId,
            },
            success: (response) => {
                let shopList = [];
                for (let i = 0; i < response.data.length; i++) {
                    shopList.push([]);
                }
                setShopList(shopList);

                let loadList = [];
                for (let i = 0; i < response.data.length; i++) {
                    loadList.push(false);
                }
                setLoadList(loadList);

                let activityCategoryIndex = -1;
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].activityCategoryId == props.match.params.activityCategoryId) {
                        activityCategoryIndex = i;

                        break;
                    }
                }

                setActivityCategoryList(response.data);

                handleLoadShop(cityId, activityCategoryIndex, response.data[activityCategoryIndex].activityCategoryId, loadList, shopList);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {},
        });
    };

    const handleLoadShop = (cityId, activityCategoryIndex, activityCategoryId, loadList, shopList) => {
        setActivityCategoryIndex(activityCategoryIndex);
        setActivityCategoryId(activityCategoryId);

        loadList[activityCategoryIndex] = true;
        setLoadList([...loadList]);

        if (request.current) {
            return;
        }
        request.current = true;

        if (isLaunch) {
            ChuangShiOS.Util.toastLoad();
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/consumer/mobile/v1/shop/list",
            data: {
                activityId: props.match.params.activityId,
                activityCategoryId: activityCategoryId,
                cityId: cityId,
            },
            success: (response) => {
                ChuangShiOS.Util.toastHide();

                shopList[activityCategoryIndex] = response.data;
                setShopList([...shopList]);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleClickShop = (activityCategoryId, shopId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/shop/activity/detail/" + props.match.params.activityId + "/" + activityCategoryId + "/" + shopId,
        });
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back={ ChuangShiOS.Util.getParameter('reserve')==='disable'?"":"返回"}
                backArrow={ChuangShiOS.Util.getParameter('reserve')!=='disable'}
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
                right={
                    isLaunch ? (
                        <div
                            onClick={() => {
                                setIsCity(true);
                            }}
                        >
                            [选择城市] <EnvironmentOutline />
                            {cityName}
                        </div>
                    ) : null
                }
            >
                门店列表
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                <div className={Style.page}>
                    <Tabs
                        activeKey={activityCategoryIndex}
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#ffffff",
                            "--title-font-size": "3.73333vw",
                        }}
                        onChange={(activityCategoryIndex) => {
                            handleLoadShop(
                                cityId,
                                activityCategoryIndex,
                                activityCategoryList[activityCategoryIndex].activityCategoryId,
                                loadList,
                                shopList
                            );

                            // ChuangShiOS.Util.redirectTo(props, {
                            //     url:
                            //         "/shop/activity/index/" +
                            //         props.match.params.activityId +
                            //         "/" +
                            //         activityCategoryList[activityCategoryIndex].activityCategoryId,
                            // });
                        }}
                    >
                        {activityCategoryList.map((activityCategory, activityCategoryIndex) => {
                            return <Tabs.Tab key={activityCategoryIndex} title={activityCategory.activityCategoryName}></Tabs.Tab>;
                        })}
                    </Tabs>
                    {shopList.length > 0
                        ? activityCategoryList.map((activityCategory, activityCategoryIndex) => {
                            return (
                                <ChuangShiOS.Content
                                    key={activityCategoryIndex}
                                    isLaunch={true}
                                    isEmpty={shopList[activityCategoryIndex].length == 0}
                                    visible={activityCategory.activityCategoryId == activityCategoryId}
                                >
                                    <List
                                        style={{
                                            "--border-top": "none",
                                        }}
                                        mode="card"
                                    >
                                        {shopList[activityCategoryIndex].map((shop, shopIndex) => {
                                            return (
                                                <List.Item
                                                    key={shopIndex}
                                                    onClick={() => {
                                                        handleClickShop(activityCategory.activityCategoryId, shop.shopId);
                                                    }}
                                                >
                                                    <div className={Style.shop}>
                                                        {/*<Image
                                                            className={Style.shop_image}
                                                            lazy
                                                            src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(shop.shopImage, 375)}
                                                            fit="cover"
                                                        />*/}
                                                        <img alt="" className={Style.shop_image} src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(shop.shopImage, 375)}></img>
                                                        <div>
                                                            <div className={Style.shop_row}>
                                                                <div className={Style.shop_title}>
                                                                    {shop.merchantName} - {shop.shopName}
                                                                </div>
                                                            </div>
                                                            <div className={Style.shop_row}>
                                                                <div>电话: </div>
                                                                <div className={Style.shop_value}>{shop.shopTel}</div>
                                                            </div>
                                                            <div className={Style.shop_row}>
                                                                <div>城市: </div>
                                                                <div className={Style.shop_value}>
                                                                    {shop.provinceName}/{shop.cityName}
                                                                </div>
                                                            </div>
                                                            <div className={Style.shop_row}>
                                                                <div>地址: </div>
                                                                <div className={Style.shop_value}>{shop.shopAddress}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            );
                                        })}
                                    </List>
                                    <InfiniteScroll hasMore={false} />
                                </ChuangShiOS.Content>
                            );
                        })
                        : null}
                </div>
            </ChuangShiOS.Content>
            <CascadePicker
                value={[provinceId, cityId]}
                visible={isCity}
                options={cityList}
                onConfirm={(value) => {
                    setProvinceId(value[0]);
                    setCityId(value[1]);

                    for (let i = 0; i < cityList.length; i++) {
                        if (cityList[i].value == value[0]) {
                            for (let j = 0; j < cityList[i].children.length; j++) {
                                if (cityList[i].children[j].value == value[1]) {
                                    setCityName(cityList[i].children[j].label);
                                }
                            }
                        }
                    }

                    handleLoadShop(
                        value[1],
                        activityCategoryIndex,
                        activityCategoryList[activityCategoryIndex].activityCategoryId,
                        loadList,
                        shopList
                    );

                    setIsCity(false);
                }}
                onClose={() => {
                    setIsCity(false);
                }}
            />
        </ChuangShiOS.Page>
    );
};

ShopActivityIndex.propTypes = {};

ShopActivityIndex.defaultProps = {};

export default withRouter(ShopActivityIndex);
