import {wechatPay, isWechat} from "../../utils/wechat";
import ChuangShiOS from "chuangshi-os-mobile-component";
import Application from "../../common/Application";

export const tradePay = (props,tradeId,cb=()=>{}) => {
    let paymentType = "WECHAT_PAY_H5"; // 微信H5支付
    if (isWechat()){
        paymentType = "WECHAT_PAY_JSAPI" // 微信JSAPI支付
    }
    let params = {paymentType,tradeId};
    ChuangShiOS.Util.request(props, {
        url: Application.host + "/windeal/trade/consumer/mobile/pay",
        data: params,
        success: (response) => {
            // 支付成功回调，刷新订单列表
            window.wechatPaySuccess = ()=>{cb();}
            // 调用支付
            wechatPay(response.data)
        },
        error: (response) => {
            ChuangShiOS.Util.toastError({
                message: response.message,
                complete: () => {},
            });
        },
        complete: (response) => {
        },
    });
};
