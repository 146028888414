import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";

import TradeIndex from "./Index";
import TradeConfirm from "./Confirm";
import TradeResult from "./Result";
import TradeResultEfund from "./ResultEfund"
import TradeDetail from "./Detail";

const data = [
    <Route
        exact
        key="TradeActivityIndex"
        path="/trade/index/:activityId"
        render={(props) => {
            return (
                <KeepAlive name={"/trade/index/" + props.match.params.activityId} key={props.match.params.activityId}>
                    <TradeIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="TradeIndex"
        path="/trade/index"
        render={(props) => {
            return (
                <KeepAlive name={"/trade/index"}>
                    <TradeIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="TradeActivityConfirm"
        path="/trade/activity/confirm/:activityId/:activityGoodsSkuId/:reserveDate"
        render={(props) => {
            return <TradeConfirm {...props} />;
        }}
    />,
    <Route
        exact
        key="TradeBenefitConfirm"
        path="/trade/benefit/confirm/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId/:reserveDate"
        render={(props) => {
            return <TradeConfirm {...props} />;
        }}
    />,
    <Route
        exact
        key="TradeActivityResult"
        path="/trade/activity/result/:activityId/:tradeId"
        render={(props) => {
            return <TradeResult {...props} />;
        }}
    />,
    <Route
        exact
        key="TradeActivityResultEfund"
        path="/trade/activity/result/efund/:activityId/:tradeId"
        render={(props) => {
            return <TradeResultEfund {...props} />;
        }}
    />,
    <Route
        exact
        key="TradeBenefitResult"
        path="/trade/benefit/result/:tradeId"
        render={(props) => {
            return <TradeResult {...props} />;
        }}
    />,
    <Route
        exact
        key="TradDetail"
        path="/trade/detail/:tradeId"
        render={(props) => {
            return <TradeDetail {...props} />;
        }}
    />,
];

export default data;
