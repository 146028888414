import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

let routers = [];
let routerContexts = require.context("../", true, /^.*\/Router\.js$/);
routerContexts.keys().forEach((fileName) => {
    routers = routers.concat(routerContexts(fileName).default);
});

const MainIndex = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.location.pathname == "/") {
            ChuangShiOS.Util.redirectTo(props, {
                url: Application.homePath,
            });
        }

        let token = ChuangShiOS.Util.getToken();

        if (token) {
            handleLoadMember();
        }

        ChuangShiOS.Notification.on("member_find", this, (data) => {
            let token = ChuangShiOS.Util.getToken();

            if (token) {
                handleLoadMember();
            }
        });

        return () => {
            ChuangShiOS.Notification.remove("member_find", this);
        };

        //eslint-disable-next-line
    }, []);

    const handleLoadMember = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/consumer/mobile/v1/find",
            data: {},
            success: (response) => {
                dispatch({
                    type: "main",
                    data: {
                        member: response.data,
                    },
                });

                ChuangShiOS.Notification.emit("member_find_succes", {});
            },
            error: (response) => {},
            complete: (response) => {},
        });
    };

    return (
        <React.Fragment>
            {routers.map((router) => {
                return router;
            })}
        </React.Fragment>
    );
};

MainIndex.propTypes = {};

MainIndex.defaultProps = {};

export default withRouter(MainIndex);
