import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import {Button, NavBar, Popup, Swiper} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";
import moment from "moment";

import Application from "../../common/Application";

import Calendar from "../../component/calendar/Index";

import Style from "./Index.module.css";

const MyIndex = (props) => {
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [goods, setGoods] = useState({
        goodsImageList: [],
        goodsReserveWeekList: []
    });
    const [isReserveDate, setIsReserveDate] = useState(false);
    const [isConfirmReserveDate, setIsConfirmReserveDate] = useState(false);
    const [reserveDate, setReserveDate] = useState("");
    const [minDate, setMinDate] = useState(0);
    const [maxDate, setMaxDate] = useState(0);
    const [calendarList, setCalendarList] = useState([]);
    const [isSubmit, setIsSubmit] = useState(true);

    useEffect(() => {
        handleLoadGoods();
        handleLoadDate();
        //eslint-disable-next-line
    }, []);

    useActivate(() => {
    });

    useUnactivate(() => {
    });

    const setGoodsStorage = (v) => {
        setGoods(v);
        localStorage.setItem("windeal-h5-goods", JSON.stringify(v));
    };

    const handleLoadGoods = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        let url = "";
        let data = {};
        switch (props.match.path) {
            case "/goods/activity/index/:activityId/:activityGoodsSkuId":
                url = "/windeal/activity/consumer/mobile/v1/goods/find";
                data = {
                    activityGoodsSkuId: props.match.params.activityGoodsSkuId
                };
                break;
            case "/goods/benefit/index/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId":
                url = "/windeal/member/benefit/consumer/mobile/v1/goods/find";
                data = {
                    memberBenefitId: props.match.params.memberBenefitId,
                    benefitGoodsSkuId: props.match.params.benefitGoodsSkuId
                };
                break;
            default:
                break;
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                setGoodsStorage(response.data);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {
                    }
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            }
        });
    };

    const handleLoadDate = () => {
        let url = "";
        let data = {};
        switch (props.match.path) {
            case "/goods/activity/index/:activityId/:activityGoodsSkuId":
                url = "/windeal/activity/consumer/mobile/v1/date/find";

                data = {
                    activityGoodsSkuId: props.match.params.activityGoodsSkuId
                };

                break;
            case "/goods/benefit/index/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId":
                url = "/windeal/member/benefit/consumer/mobile/v1/date/find";

                data = {
                    memberBenefitId: props.match.params.memberBenefitId,
                    benefitCategoryId: props.match.params.benefitCategoryId,
                    benefitGoodsSkuId: props.match.params.benefitGoodsSkuId
                };

                break;
            default:
                break;
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                setMinDate(response.data.minDate);
                setMaxDate(response.data.maxDate);
                setCalendarList(response.data.calendarList);

                switch (props.match.path) {
                    case "/goods/activity/index/:activityId/:activityGoodsSkuId":
                        break;
                    case "/goods/benefit/index/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId":
                        setIsSubmit(response.data.benefitIsSave);

                        break;
                    default:
                        break;
                }
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {
                    }
                });
            },
            complete: (response) => {
            }
        });
    };

    const handleClickConfirm = () => {
        if (isConfirmReserveDate && reserveDate) {
            ChuangShiOS.Util.validateLogin(props, {
                complete: () => {
                    switch (props.match.path) {
                        case "/goods/activity/index/:activityId/:activityGoodsSkuId":
                            ChuangShiOS.Util.navigateTo(props, {
                                url:
                                    "/trade/activity/confirm/" +
                                    props.match.params.activityId +
                                    "/" +
                                    props.match.params.activityGoodsSkuId +
                                    "/" +
                                    moment(reserveDate).valueOf()
                            });
                            break;
                        case "/goods/benefit/index/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId":
                            ChuangShiOS.Util.navigateTo(props, {
                                url:
                                    "/trade/benefit/confirm/" +
                                    props.match.params.memberBenefitId +
                                    "/" +
                                    props.match.params.benefitCategoryId +
                                    "/" +
                                    props.match.params.benefitGoodsSkuId +
                                    "/" +
                                    moment(reserveDate).valueOf()
                            });
                            break;
                        default:
                            break;
                    }
                }
            });
        }
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid"
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                {goods.merchantName}
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                <div style={{display: "flex", flexDirection: "column", height: "100%", overflow: "hidden"}}>
                    <div style={{flex: 1, overflowY: "scroll", overflowX: "hidden"}}>
                        <div className={Style.banner}>
                            {goods.goodsImageList.length > 0 ? (
                                <Swiper autoplay loop>
                                    {goods.goodsImageList.map((goodsImage, goodsImageIndex) => {
                                        return (
                                            <Swiper.Item key={goodsImageIndex}>
                                                <div
                                                    key={goodsImageIndex}
                                                    className={Style.banner_image}
                                                    style={{
                                                        backgroundImage:
                                                            "url(" +
                                                            Application.imagePreviewHost +
                                                            ChuangShiOS.Util.scaleImage(goodsImage, 750) +
                                                            ")"
                                                    }}
                                                ></div>
                                            </Swiper.Item>
                                        );
                                    })}
                                </Swiper>
                            ) : null}
                        </div>
                        <div className={Style.goods}>
                            <div className={Style.goods_title}>{goods.goodsSkuName}</div>
                            <div className={Style.goods_price}>
                                {goods.activityIsPoints ? (
                                    <span className={Style.goods_sale_price}>{goods.goodsSkuPoints}积点</span>) : (
                                    <span className={Style.goods_sale_price}>¥{goods.goodsSkuSalePrice}</span>)}
                                {goods.marketPriceIsHidden ? null : (
                                    <span className={Style.goods_market_price}>¥{goods.goodsSkuMarketPrice}</span>)}
                            </div>
                            <div className={Style.goods_text}>
                                <div className={Style.shop_row}>
                                    <div>预定：</div>
                                    <div className={Style.shop_value}>
                                        {goods.goodsReserveWeekList.sort().map((goodsReserveWeek, goodsReserveWeekIndex) => {
                                            return (
                                                <span key={goodsReserveWeekIndex}>
                                                    {goodsReserveWeek == 0 ? null : null}
                                                    {goodsReserveWeek == 1 ? "周一" : null}
                                                    {goodsReserveWeek == 2 ? "周二" : null}
                                                    {goodsReserveWeek == 3 ? "周三" : null}
                                                    {goodsReserveWeek == 4 ? "周四" : null}
                                                    {goodsReserveWeek == 5 ? "周五" : null}
                                                    {goodsReserveWeek == 6 ? "周六" : null}
                                                    {goodsReserveWeekIndex + 1 < goods.goodsReserveWeekList.length && goodsReserveWeek > 0
                                                        ? "，"
                                                        : null}
                                                </span>
                                            );
                                        })}
                                        {goods.goodsReserveWeekList.indexOf(0) > -1
                                            ? (goods.goodsReserveWeekList.length > 1 ? "，" : "") + "周日"
                                            : null}
                                    </div>
                                </div>
                                <div className={Style.shop_row}>
                                    <div>时间：</div>
                                    <div className={Style.shop_value}>
                                        {goods.goodsReserveStartTime} ~ {goods.goodsReserveEndTime}
                                    </div>
                                </div>
                                <div className={Style.shop_row}>
                                    <div>酒店：</div>
                                    <div className={Style.shop_value}>{goods.merchantName}</div>
                                </div>
                                <div className={Style.shop_row}>
                                    <div>门店：</div>
                                    <div className={Style.shop_value}>{goods.shopName}</div>
                                </div>
                                <div className={Style.shop_row}>
                                    <div>电话：</div>
                                    <div className={Style.shop_value}>{goods.shopTel}</div>
                                </div>
                                <div className={Style.shop_row}>
                                    <div>城市：</div>
                                    <div className={Style.shop_value}>
                                        {goods.provinceName}/{goods.cityName}
                                    </div>
                                </div>
                                <div className={Style.shop_row}>
                                    <div>地址：</div>
                                    <div className={Style.shop_value}>{goods.shopAddress}</div>
                                </div>
                                {goods.goodsSkuName && (goods.goodsSkuName.indexOf("买一赠一") !== -1) ? (
                                    <div className={Style.shop_row} style={{color: "#FF5722", fontWeight: "bold"}}>
                                        <div>注意：</div>
                                        <div className={Style.shop_value}>
                                            <p>1. 优惠权益不可拆分使用</p>
                                            <p>2. 成功预定后未出席请提前3小时取消订单或17：00前致电客服取消，如未取消视同该权益已使用</p>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {goods.goodsChildren ? (
                            <div className={Style.card}>
                                <div className={Style.card_title}>儿童说明</div>
                                <div className={Style.card_content}
                                     dangerouslySetInnerHTML={{__html: goods.goodsChildren}}></div>
                            </div>
                        ) : null
                        }
                        <div className={Style.card}>
                            <div className={Style.card_title}>使用条件</div>
                            <div className={Style.card_content}
                                 dangerouslySetInnerHTML={{__html: goods.goodsContent}}></div>
                        </div>
                        <div className={Style.empty}></div>
                    </div>
                    <div className={Style.footer}
                         style={ChuangShiOS.Util.getParameter("reserve") === "disable" ? {display: "none"} : {}}>
                        <Button
                            className={Style.footer_button}
                            disabled={!isSubmit}
                            color="primary"
                            onClick={() => {
                                setIsReserveDate(true);
                            }}
                        >
                            {isSubmit ? "立即预约" : "权益已用完"}
                        </Button>
                    </div>
                </div>
            </ChuangShiOS.Content>
            <Popup
                visible={isReserveDate}
                onMaskClick={() => {
                    setIsReserveDate(false);
                    setIsConfirmReserveDate(false);
                }}
                afterClose={() => {
                    handleClickConfirm();
                }}
            >
                <Calendar
                    value={reserveDate}
                    title="预定日期"
                    startDay={minDate}
                    endDay={maxDate}
                    calendarList={calendarList}
                    onClose={() => {
                        setIsReserveDate(false);
                        setIsConfirmReserveDate(false);
                    }}
                    onConfirm={(value) => {
                        setIsReserveDate(false);
                        setIsConfirmReserveDate(true);
                        setReserveDate(value);
                    }}
                />
            </Popup>
        </ChuangShiOS.Page>
    );
};

MyIndex.propTypes = {};

MyIndex.defaultProps = {};

export default withRouter(MyIndex);
