import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate, useAliveController} from "react-activation";
import {NavBar, Card, List} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Footer from "../../component/footer/Index";

import Style from "./Index.module.css";

import MemberImage from "../my/image/memberImage.png";

const BenefitIndex = (props) => {
    const store = useSelector((store) => store);
    const {drop} = useAliveController();
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [benefitList, setBenefitList] = useState([]);

    useEffect(() => {
        drop(new RegExp("/benefit/detail/*"));

        let token = ChuangShiOS.Util.getToken();
        if (token) {
            setIsLogin(true);

            handleLoadBenefit();
        } else {
            setIsLaunch(true);
            setIsLogin(false);
        }

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        drop(new RegExp("/benefit/detail/*"));

        let token = ChuangShiOS.Util.getToken();
        if (token) {
            setIsLogin(true);

            handleLoadBenefit();
        } else {
            setIsLogin(false);

            setBenefitList([]);
        }
    });

    useUnactivate(() => {});

    const handleLoadBenefit = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/benefit/consumer/mobile/v1/list",
            data: {},
            success: (response) => {
                setBenefitList(response.data);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleClickBenefit = (benefitId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/benefit/detail/" + benefitId,
        });
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back={null}
                right={
                    isLogin ? (
                        <div
                            onClick={() => {
                                ChuangShiOS.Util.navigateTo(props, {
                                    url: "/benefit/exchange",
                                });
                            }}
                        >
                            兑换
                        </div>
                    ) : null
                }
            >
                我德权益
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} backgroundColor="#82d2d2">
                {isLogin ? (
                    <List>
                        <List.Item
                            hasArrow
                            prefix={<img alt="" src={MemberImage} className={Style.profile_image} />}
                            description=""
                            onClick={() => {
                                ChuangShiOS.Util.navigateTo(props, {
                                    url: "/profile/index/" + props.match.params.activityId,
                                });
                            }}
                        >
                            <React.Fragment>
                                <div className={Style.profile_title}>{store.main.member.memberPhone}</div>
                                <div>
                                    <span className={Style.profile_level}>普通会员</span>
                                </div>
                            </React.Fragment>
                        </List.Item>
                    </List>
                ) : (
                    <List>
                        <List.Item
                            hasArrow
                            prefix={<img alt="" src={MemberImage} className={Style.profile_image} />}
                            description=""
                            onClick={() => {
                                ChuangShiOS.Util.navigateTo(props, {
                                    url: "/login/index",
                                });
                            }}
                        >
                            <React.Fragment>
                                <div className={Style.login_title}>登录/注册</div>
                                <div className={Style.login_tips}>登录后更多权益</div>
                            </React.Fragment>
                        </List.Item>
                        <List.Item>
                            <div>本系统已完成升级，点击上方“登录/注册” </div>
                            <ol>
                                <li>新用户请点击"免费注册"进行本系统注册及后续权益码绑定、使用。</li>
                                <li>如对权益有任何疑问，请拨打400-005-4577咨询。</li>
                            </ol>
                        </List.Item>
                    </List>
                )}
                {benefitList.map((benefit, benefitIndex) => {
                    return (
                        <Card
                            key={benefitIndex}
                            className={Style.card}
                            title={benefit.benefitName}
                            onClick={() => {
                                handleClickBenefit(benefit.memberBenefitId);
                            }}
                        >
                            <div>权益码号</div>
                            <div className={Style.number}>
                                {benefit.benefitCodeList.map((benefitCode, benefitCodeIndex) => {
                                    return (
                                        <React.Fragment key={benefitCodeIndex}>
                                            {benefitCode}
                                            {(benefitCodeIndex + 1) % 4 == 0 ? <span className={Style.space}></span> : null}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <div className={Style.empty_2}></div>
                            <div>权益码有效期</div>
                            <div>
                                {benefit.memberBenefitStart} ~ {benefit.memberBenefitEnd}
                            </div>
                        </Card>
                    );
                })}
                <div className={Style.empty}></div>
            </ChuangShiOS.Content>
            <Footer index={0} activityId=""></Footer>
        </ChuangShiOS.Page>
    );
};

BenefitIndex.propTypes = {};

BenefitIndex.defaultProps = {};

export default withRouter(BenefitIndex);
