import React from "react";
import {Route} from "react-router-dom";

import ForgetIndex from "./Index";

const data = [
    <Route
        exact
        key="ForgetIndex"
        path="/forget/index"
        render={(props) => {
            return <ForgetIndex {...props} />;
        }}
    />,
];

export default data;
