import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {TabBar} from "antd-mobile";

import Style from "./Index.module.css";

import Home from "./image/home.png";
import HomeActive from "./image/home_active.png";
import Trade from "./image/trade.png";
import TradeActive from "./image/trade_active.png";
import My from "./image/my.png";
import MyActive from "./image/my_active.png";

const Footer = (props) => {
    const [tabbarIndex, setTabbarIndex] = useState(-1);

    useEffect(() => {
        setTabbarIndex(props.index);

        //eslint-disable-next-line
    }, []);

    const handleClickTabbar = (index) => {
        if (index != tabbarIndex) {
            let url = "";
            if (index == 0) {
                url = props.activityId ? "/activity/index/" + props.activityId : "/benefit/index";
            } else if (index == 1) {
                url = props.activityId ? "/trade/index/" + props.activityId : "/trade/index";
            } else if (index == 2) {
                url = props.activityId ? "/my/index/" + props.activityId : "/my/index";
            }

            props.history.replace({
                pathname: url,
                query: {orderTabBarIsHidden: props.orderTabBarIsHidden},
            });

            // ChuangShiOS.Util.redirectTo(props, {
            //     url: url,
            // });
        }
    };

    return (
        <div className={Style.footer}>
            <TabBar
                activeKey={tabbarIndex + ""}
                onChange={(value) => {
                    handleClickTabbar(value);
                }}
            >
                <TabBar.Item
                    key={"0"}
                    title={props.activityId ? "活动" : "权益"}
                    icon={
                        tabbarIndex == 0 ? (
                            <img src={HomeActive} className={Style.footer_item_image} alt="" />
                        ) : (
                            <img src={Home} className={Style.footer_item_image} alt="" />
                        )
                    }
                />

                {props.orderTabBarIsHidden === true ? null : (
                    <TabBar.Item
                        key={"1"}
                        title="订单"
                        icon={
                            tabbarIndex == 1 ? (
                                <img src={TradeActive} className={Style.footer_item_image} alt="" />
                            ) : (
                                <img src={Trade} className={Style.footer_item_image} alt="" />
                            )
                        }
                    />
                )}

                {props.activityId ? (
                    <TabBar.Item
                        key={"2"}
                        title="我的"
                        icon={
                            tabbarIndex == 2 ? (
                                <img src={MyActive} className={Style.footer_item_image} alt="" />
                            ) : (
                                <img src={My} className={Style.footer_item_image} alt="" />
                            )
                        }
                    />
                ) : null}
            </TabBar>
        </div>
    );
};

Footer.propTypes = {
    index: PropTypes.number.isRequired,
    activityId: PropTypes.string,
};

Footer.defaultProps = {};

export default withRouter(Footer);
