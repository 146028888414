import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {ExclamationTriangleOutline} from "antd-mobile-icons";
import {Toast,Tag, Input, Button,Checkbox} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./Coupon.module.css";

const TradeCoupon = (props) => {
    const store = useSelector((store) => store);
    //const [form] = Form.useForm();
    const request = useRef(false);
    //const [isLaunch, setIsLaunch] = useState(false);
    const [code,setCode] = useState("");
    const [coupons,setCoupons] = useState([]);
    const txnAmt = props.txnAmt;
    const [checkedCodes,setCheckedCodes] = useState([]);
    const [disPoints,setDisPoints] = useState(0);
    const [disAmt,setDisAmt] = useState(0);

    useEffect(() => {
        // 光大私行短链券自动兑换
        let couponCode = localStorage.getItem("couponCode");
        if (couponCode){
            setCode(couponCode);
            localStorage.setItem("couponCode","");
            handlerExchange(couponCode,false);
        }

        loadData();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        //eslint-disable-next-line
    }, [store.main.member.memberPhone]);
    useActivate(() => {});
    useUnactivate(() => {});

    // 页面加载执行兑换，目前仅用于光大私行
    const handlerExchange = (couponCode,toastShow=true)=>{
        console.log("handlerExchange couponCode:",couponCode)
        if (!couponCode||couponCode.length!==12) {
            ChuangShiOS.Util.toastError({
                message: "请输入正确券码",
                complete: () => {},
            });
            return;
        }
        if (request.current) {
            return;
        }
        request.current = true;
        ChuangShiOS.Util.toastLoad();

        // 调用兑换接口
        let url = "/windeal/coupon/consumer/mobile/v1/exchange";
        let data = {code:couponCode}
        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                toastShow && Toast.show({
                    icon: 'success',
                    content: '兑换成功',
                    duration:3000
                })
                setCode("")
            },
            error: (response) => {
                toastShow && Toast.show({
                    icon:<ExclamationTriangleOutline />,
                    content:response.message,
                    duration:3000
                })
            },
            complete: (response) => {
                request.current = false;
                ChuangShiOS.Util.toastHide();

                loadData();// 刷新券列表
            },
        });
    }

    // 点击兑换按钮
    const clickHandlerExchange = ()=>{
        console.log("clickHandlerExchange couponCode:",code)
        if (!code||code.length!==12) {
            ChuangShiOS.Util.toastError({
                message: "请输入正确券码",
                complete: () => {},
            });
            return;
        }
        if (request.current) {
            return;
        }
        request.current = true;
        ChuangShiOS.Util.toastLoad();

        // 调用兑换接口
        let url = "/windeal/coupon/consumer/mobile/v1/exchange";
        let data = {code:code}
        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                Toast.show({
                    icon: 'success',
                    content: '兑换成功',
                    duration:3000
                })
                setCode("")
            },
            error: (response) => {
                Toast.show({
                    icon:<ExclamationTriangleOutline />,
                    content:response.message,
                    duration:3000
                })
            },
            complete: (response) => {
                request.current = false;
                ChuangShiOS.Util.toastHide();

                loadData();// 刷新券列表
            },
        });
    }

    // 加载券列表
    const loadData = () => {
        let activityId = props.activityId;
        if (!activityId){
            ChuangShiOS.Util.toastError({
                message: "活动编号不能为空",
                complete: () => {},
            });
            return;
        }
        if (request.current) {
            return;
        }
        request.current = true;
        ChuangShiOS.Util.toastLoad();

        let url = "/windeal/coupon/consumer/mobile/v1/list";
        let data = {activityId,code}
        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                setCoupons([...response.data]);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;
                ChuangShiOS.Util.toastHide();
            },
        });
    };

    const checkboxGroupChange = (checkedValues)=>{
        console.log("checkboxGroupChange coupons ",coupons)
        console.log("checkboxGroupChange checkedValues ",checkedValues)
        let disPoints = 0;
        let disAmt = 0;
        for (let code of checkedValues) {
            for (let coupon of coupons) {
                if (coupon.code === code){
                    // 兑换，不可叠加使用
                    if(coupon.type==="T1"){
                        disAmt = coupon.faceValue
                        setCheckedCodes([coupon.code])
                        break
                    }
                    // 满减，可以叠加使用
                    if (coupon.type==="T2"){
                        if (coupon.facePoints>0){
                            disPoints += coupon.facePoints
                            setCheckedCodes([...checkedValues])
                        }
                        if (coupon.faceValue>0){
                            disAmt += coupon.faceValue
                            setCheckedCodes([...checkedValues])
                        }
                        break
                    }
                    // 满折，不可叠加使用
                    if(coupon.type==="T3"){
                        disAmt = (props.txnAmt - (props.txnAmt*(coupon.discountRate/10))).toFixed(2);
                        setCheckedCodes([coupon.code])
                        break
                    }
                }
            }
        }
        setDisPoints(disPoints)
        setDisAmt(disAmt)
    }

    // 确认
    const handlerConfirm =()=>{
        // 现金抵扣
        if (disAmt>0){
            props.couponDiscounts(checkedCodes,disAmt>=props.txnAmt?props.txnAmt:disAmt,parseFloat((disAmt>=props.txnAmt?0:props.txnAmt-disAmt).toFixed(2)))
        }
        // 积点抵扣
        if (disPoints>0){
            props.couponDiscountsPoints(checkedCodes,disPoints>props.txnPoints?props.txnPoints:disPoints,disPoints>=props.txnPoints?0:props.txnPoints-disPoints)
        }
    }

    return (
        <div className={Style.content}>
            <h1 className={Style.pageTitle}>优惠券</h1>
            <div className={Style.exchange}>
                <Input
                    className={Style.input}
                    placeholder="请输入券码"
                    value={code}
                    clearable
                    type="tel"
                    maxLength={12}
                    onChange={val => {
                        setCode(val)
                    }}></Input>
                <Button  color='primary' fill='solid' className={Style.exbtn} onClick={clickHandlerExchange}>兑换</Button>
            </div>
            <div className={Style.ksycode}>
                <div className={Style.title}>可使用优惠券</div>
                <div className={Style.list}>
                    <Checkbox.Group onChange={(checkedValues)=>checkboxGroupChange(checkedValues)} value={checkedCodes} name="couponChecked">
                        { coupons&&coupons.length>0 && coupons.map((item,index)=> {
                        return item.type==="T1"||item.fullValue<=txnAmt? (
                            <div className={Style.couponBlock} key={index}>
                                <div className={Style.r1}>
                                    <div className={Style.r1Left}>
                                        <h3>{item.couponName}</h3>
                                        <p>有效期至{item.epTime}</p>
                                    </div>
                                    <div className={Style.r1Right}>
                                        { item.type==="T1"?(<span><em>兑换券</em></span>):null}
                                        { item.type==="T2"?(item.facePoints>0?(<span><em>{item.facePoints}</em> 积点</span>):(<span>¥<em>{item.faceValue}</em></span>)):null}
                                        { item.type==="T3"?(<span><em>{item.discountRate}折券</em></span>):null}
                                        { item.type==="T2" || item.type==="T3"?(item.fullValue===0&&item.facePoints===0?(<p>无门槛</p>):(<p>满{item.fullValue||item.fullPoints}可用</p>)):null}
                                    </div>
                                    <div className={Style.r1Right2}>
                                        <Checkbox value={item.code}></Checkbox>
                                    </div>
                                </div>
                                <div className={Style.r2}><Tag color='warning'>{item.typeLabel}</Tag> {item.explain}</div>
                            </div>):null}
                    ) }
                    </Checkbox.Group>
                </div>
            </div>
            <div className={Style.bkycode}>
                <div className={Style.title}>不可用优惠券</div>
                <div className={Style.list}>
                    { coupons&&coupons.length>0 && coupons.map((item,index)=>{
                        return item.fullValue>txnAmt?(
                            <div className={Style.couponBlock} key={index} style={{opacity:"0.5"}}>
                                <div className={Style.r1}>
                                    <div className={Style.r1Left}>
                                        <h3>{item.couponName}</h3>
                                        <p>有效期至{item.epTime}</p>
                                    </div>
                                    <div className={Style.r1Right}>
                                        { item.type==="T1"?(<span><em>兑换券</em></span>):null}
                                        { item.type==="T2"?(<span>¥<em>{item.faceValue}</em></span>):null}
                                        { item.type==="T3"?(<span><em>{item.discountRate}折券</em></span>):null}
                                        { item.type==="T2" || item.type==="T3"?(<p>满{item.fullValue}可用</p>):null}
                                    </div>
                                </div>
                                <div className={Style.r2}><Tag color='warning'>{item.typeLabel}</Tag> {item.explain}</div>
                            </div>
                        ):null;
                    }) }
                </div>
            </div>
            <div className={Style.confirmPadding}></div>
            <div className={Style.confirm}>
                <div className={Style.confirmLeft}>{props.activityIsPoints?(<span>券抵扣 <b>{disPoints>props.txnPoints?props.txnPoints:parseFloat(disPoints.toFixed(2))}</b> 积点，还需 {disPoints>props.txnPoints?0:parseFloat((props.txnPoints-disPoints).toFixed(2))} 积点</span>):null} </div>
                <div className={Style.confirmRight} onClick={handlerConfirm}>确认</div>
            </div>
        </div>
    );
};

TradeCoupon.propTypes = {};

TradeCoupon.defaultProps = {};

export default TradeCoupon;
