const initialState = {
    member: {},
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case "main":
            return Object.assign({}, state, action.data);

        default:
            return state;
    }
}

const data = {
    name: "main",
    reducer: reducer,
};

export default data;
