import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate, useAliveController} from "react-activation";
import {NavBar, Tabs, List, InfiniteScroll, CascadePicker} from "antd-mobile";
import {EnvironmentOutline} from "antd-mobile-icons";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./BenefitIndex.module.css";

const ShopBenefitIndex = (props) => {
    const request = useRef(false);
    const {drop} = useAliveController();
    const [isLaunch, setIsLaunch] = useState(false);
    const [benefitCategoryId, setBenefitCategoryId] = useState("");
    const [benefitCategoryIndex, setBenefitCategoryIndex] = useState(-1);
    const [benefitCategoryList, setBenefitCategoryList] = useState([]);
    const [shopList, setShopList] = useState([]);
    const [loadList, setLoadList] = useState([]);
    const [isCity, setIsCity] = useState(false);
    const [provinceId, setProvinceId] = useState("1415849331554127872");
    const [cityId, setCityId] = useState("1415861649142845440");
    const [cityName, setCityName] = useState("上海市");
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        drop(new RegExp("/shop/benefit/detail"));

        setBenefitCategoryId(props.match.params.benefitCategoryId);

        handleLoadCity();

        handleLoadBenefitCategory();

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        drop(new RegExp("/shop/benefit/detail"));
    });

    useUnactivate(() => {});

    const handleLoadCity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/consumer/mobile/v1/list",
            data: {},
            success: (response) => {
                let cityList = response.data;
                cityList.unshift({
                    label: "全国",
                    children: [
                        {
                            label: "全国",
                            value: "",
                        },
                    ],
                    value: "",
                });
                setCityList(cityList);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {},
        });
    };

    const handleLoadBenefitCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/benefit/consumer/mobile/v1/category/list",
            data: {
                memberBenefitId: props.match.params.memberBenefitId,
            },
            success: (response) => {
                let shopList = [];
                for (let i = 0; i < response.data.length; i++) {
                    shopList.push([]);
                }
                setShopList(shopList);

                let loadList = [];
                for (let i = 0; i < response.data.length; i++) {
                    loadList.push(false);
                }
                setLoadList(loadList);

                let benefitCategoryIndex = -1;
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].benefitCategoryId == props.match.params.benefitCategoryId) {
                        benefitCategoryIndex = i;

                        break;
                    }
                }

                setBenefitCategoryList(response.data);

                handleLoadShop(cityId, benefitCategoryIndex, response.data[benefitCategoryIndex].benefitCategoryId, loadList, shopList);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {},
        });
    };

    const handleLoadShop = (cityId, benefitCategoryIndex, benefitCategoryId, loadList, shopList) => {
        setBenefitCategoryIndex(benefitCategoryIndex);
        setBenefitCategoryId(benefitCategoryId);

        loadList[benefitCategoryIndex] = true;
        setLoadList([...loadList]);

        if (request.current) {
            return;
        }
        request.current = true;

        if (isLaunch) {
            ChuangShiOS.Util.toastLoad();
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/benefit/consumer/mobile/v1/shop/list",
            data: {
                memberBenefitId: props.match.params.memberBenefitId,
                benefitCategoryId: benefitCategoryId,
                cityId: cityId,
            },
            success: (response) => {
                ChuangShiOS.Util.toastHide();

                shopList[benefitCategoryIndex] = response.data;
                setShopList([...shopList]);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleClickShop = (benefitCategoryId, shopId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/shop/benefit/detail/" + props.match.params.memberBenefitId + "/" + benefitCategoryId + "/" + shopId,
        });
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
                right={
                    isLaunch ? (
                        <div
                            onClick={() => {
                                setIsCity(true);
                            }}
                        >
                            <EnvironmentOutline />
                            {cityName}
                        </div>
                    ) : null
                }
            >
                门店列表
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                <div className={Style.page}>
                    <Tabs
                        activeKey={benefitCategoryIndex}
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#ffffff",
                            "--title-font-size": "3.73333vw",
                        }}
                        onChange={(benefitCategoryIndex) => {
                            // handleLoadShop(
                            //     cityId,
                            //     benefitCategoryIndex,
                            //     benefitCategoryList[benefitCategoryIndex].benefitCategoryId,
                            //     loadList,
                            //     shopList
                            // );

                            ChuangShiOS.Util.redirectTo(props, {
                                url:
                                    "/shop/benefit/index/" +
                                    props.match.params.memberBenefitId +
                                    "/" +
                                    benefitCategoryList[benefitCategoryIndex].benefitCategoryId,
                            });
                        }}
                    >
                        {benefitCategoryList.map((benefitCategory, benefitCategoryIndex) => {
                            return <Tabs.Tab key={benefitCategoryIndex} title={benefitCategory.benefitCategoryName}></Tabs.Tab>;
                        })}
                    </Tabs>
                    {shopList.length > 0
                        ? benefitCategoryList.map((benefitCategory, benefitCategoryIndex) => {
                              return (
                                  <ChuangShiOS.Content
                                      key={benefitCategoryIndex}
                                      isLaunch={true}
                                      isEmpty={shopList[benefitCategoryIndex].length == 0}
                                      visible={benefitCategory.benefitCategoryId == benefitCategoryId}
                                  >
                                      <List
                                          style={{
                                              "--border-top": "none",
                                          }}
                                          mode="card"
                                      >
                                          {shopList[benefitCategoryIndex].map((shop, shopIndex) => {
                                              return (
                                                  <List.Item
                                                      key={shopIndex}
                                                      onClick={() => {
                                                          handleClickShop(benefitCategory.benefitCategoryId, shop.shopId);
                                                      }}
                                                  >
                                                      <div className={Style.shop}>
                                                          <img alt=""
                                                              className={Style.shop_image}
                                                              src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(shop.shopImage, 375)}
                                                          />
                                                          <div>
                                                              <div className={Style.shop_row}>
                                                                  <div className={Style.shop_title}>
                                                                      {shop.merchantName} - {shop.shopName}
                                                                  </div>
                                                              </div>
                                                              <div className={Style.shop_row}>
                                                                  <div>电话: </div>
                                                                  <div className={Style.shop_value}>{shop.shopTel}</div>
                                                              </div>
                                                              <div className={Style.shop_row}>
                                                                  <div>城市: </div>
                                                                  <div className={Style.shop_value}>
                                                                      {shop.provinceName}/{shop.cityName}
                                                                  </div>
                                                              </div>
                                                              <div className={Style.shop_row}>
                                                                  <div>地址: </div>
                                                                  <div className={Style.shop_value}>{shop.shopAddress}</div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </List.Item>
                                              );
                                          })}
                                      </List>
                                      <InfiniteScroll hasMore={false} />
                                  </ChuangShiOS.Content>
                              );
                          })
                        : null}
                </div>
            </ChuangShiOS.Content>
            <CascadePicker
                value={[provinceId, cityId]}
                visible={isCity}
                options={cityList}
                onConfirm={(value) => {
                    setProvinceId(value[0]);
                    setCityId(value[1]);

                    for (let i = 0; i < cityList.length; i++) {
                        if (cityList[i].value == value[0]) {
                            for (let j = 0; j < cityList[i].children.length; j++) {
                                if (cityList[i].children[j].value == value[1]) {
                                    setCityName(cityList[i].children[j].label);
                                }
                            }
                        }
                    }

                    handleLoadShop(value[1], benefitCategoryIndex, benefitCategoryList[benefitCategoryIndex].benefitCategoryId, loadList, shopList);

                    setIsCity(false);
                }}
                onClose={() => {
                    setIsCity(false);
                }}
            />
        </ChuangShiOS.Page>
    );
};

ShopBenefitIndex.propTypes = {};

ShopBenefitIndex.defaultProps = {};

export default withRouter(ShopBenefitIndex);
