import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import {NavBar, Form, Input, Button} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./Exchange.module.css";

const BenefitExchange = (props) => {
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);

    useEffect(() => {
        setIsLaunch(true);

        //eslint-disable-next-line
    }, []);

    useActivate(() => {});

    useUnactivate(() => {});

    const handleSubmit = (data) => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.toastLoad();

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/benefit/consumer/mobile/v1/save",
            data: data,
            success: (response) => {
                ChuangShiOS.Util.toastSuccess({
                    message: response.message,
                    complete: () => {},
                });

                setTimeout(() => {
                    ChuangShiOS.Util.navigateBack(props);
                }, 2000);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                权益兑换
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} loadingColor={"#c26b60"}>
                <div className={Style.empty}></div>
                <Form
                    layout="horizontal"
                    footer={
                        <Button block type="submit" color="primary">
                            兑换
                        </Button>
                    }
                    onFinish={handleSubmit}
                >
                    <Form.Item name="benefitCode" label="权益码" rules={[{required: true, message: "请输入权益码"}]}>
                        <Input placeholder="请输入权益码" />
                    </Form.Item>
                </Form>
            </ChuangShiOS.Content>
            {/* <img className={Style.wechat} src={Image0} alt="" /> */}
        </ChuangShiOS.Page>
    );
};

BenefitExchange.propTypes = {};

BenefitExchange.defaultProps = {};

export default withRouter(BenefitExchange);
