import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";

import ActivityIndex from "./Index";
import ActivityNotice from "./Notice";

const data = [
    <Route
        exact
        key="ActivityIndex"
        path="/activity/index/:activityId"
        render={(props) => {
            return (
                <KeepAlive name={"/activity/index/" + props.match.params.activityId} key={props.match.params.activityId}>
                    <ActivityIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ActivityNotice"
        path="/activity/notice/:activityId/:activityNoticeId"
        render={(props) => {
            return <ActivityNotice {...props} />;
        }}
    />,
];

export default data;
