import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useAliveController, useUnactivate} from "react-activation";
import {InfiniteScroll, List, NavBar} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const ShopDetail = (props) => {
    const {drop} = useAliveController();
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [shop, setShop] = useState({});
    const [goodsList, setGoodsList] = useState([]);

    useEffect(() => {
        drop(new RegExp("/goods/*"));

        handleLoadShop();

        handleLoadGoods();

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        drop(new RegExp("/goods/*"));
    });

    useUnactivate(() => {});

    const handleLoadShop = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/shop/consumer/mobile/v1/find",
            data: {
                activityId: props.match.params.activityId,
                shopId: props.match.params.shopId,
            },
            success: (response) => {
                setShop(response.data);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleLoadGoods = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        let url = "";
        let data = {};
        switch (props.match.path) {
            case "/shop/activity/detail/:activityId/:activityCategoryId/:shopId":
                url = "/windeal/activity/consumer/mobile/v1/goods/sku/list";

                data = {
                    activityId: props.match.params.activityId,
                    activityCategoryId: props.match.params.activityCategoryId,
                    shopId: props.match.params.shopId,
                };

                break;
            case "/shop/benefit/detail/:memberBenefitId/:benefitCategoryId/:shopId":
                url = "/windeal/member/benefit/consumer/mobile/v1/goods/sku/list";

                data = {
                    memberBenefitId: props.match.params.memberBenefitId,
                    benefitCategoryId: props.match.params.benefitCategoryId,
                    shopId: props.match.params.shopId,
                };

                break;
            default:
                break;
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                setGoodsList(response.data);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleClickGoods = (goods) => {
        switch (props.match.path) {
            case "/shop/activity/detail/:activityId/:activityCategoryId/:shopId":
                ChuangShiOS.Util.navigateTo(props, {
                    url: "/goods/activity/index/" + props.match.params.activityId + "/" + goods.activityGoodsSkuId,
                });

                break;
            case "/shop/benefit/detail/:memberBenefitId/:benefitCategoryId/:shopId":
                ChuangShiOS.Util.navigateTo(props, {
                    url:
                        "/goods/benefit/index/" +
                        props.match.params.memberBenefitId +
                        "/" +
                        props.match.params.benefitCategoryId +
                        "/" +
                        goods.benefitGoodsSkuId,
                });

                break;
            default:
                break;
        }
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                {shop.merchantName}
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                <List
                    style={{
                        "--border-top": "none",
                    }}
                >
                    <List.Item>
                        <div className={Style.shop}>
                            <div className={Style.shop_row}>
                                <div>门店：</div>
                                <div className={Style.shop_value}>{shop.shopName}</div>
                            </div>
                            <div className={Style.shop_row}>
                                <div>电话：</div>
                                <div className={Style.shop_value}>{shop.shopTel}</div>
                            </div>
                            <div className={Style.shop_row}>
                                <div>城市：</div>
                                <div className={Style.shop_value}>
                                    {shop.provinceName}/{shop.cityName}
                                </div>
                            </div>
                            <div className={Style.shop_row}>
                                <div>地址：</div>
                                <div className={Style.shop_value}>{shop.shopAddress}</div>
                            </div>
                        </div>
                    </List.Item>
                </List>
                <div className={Style.list}>
                    <div className={Style.item}>
                        {goodsList.map((goods, goodsIndex) => {
                            return goodsIndex % 2 == 0 ? (
                                <div
                                    key={goodsIndex}
                                    className={Style.goods}
                                    onClick={() => {
                                        handleClickGoods(goods);
                                    }}
                                >
                                    {shop.shopImage ? (
                                        <div className={Style.goods_row}>
                                            <img alt=""
                                                className={Style.shop_image}
                                                src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(shop.shopImage, 375)}
                                            />
                                            <div className={Style.goods_shop}>{shop.shopName}</div>
                                        </div>
                                    ) : null}
                                    <img  alt=""
                                        className={Style.goods_image}
                                        src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(goods.goodsImage, 375)}
                                    />
                                    <div className={Style.goods_title}>{goods.goodsSkuName}</div>
                                    {goods.marketPriceIsHidden === true ? null : (<div className={Style.goods_market_price}>¥{goods.goodsSkuMarketPrice}</div>)}
                                    {goods.activityIsPoints?(<div className={Style.goods_sale_price}>{goods.goodsSkuPoints}积点</div>):(<div className={Style.goods_sale_price}>¥{goods.goodsSkuSalePrice}</div>)}
                                </div>
                            ) : null;
                        })}
                    </div>
                    <div className={Style.item}>
                        {goodsList.map((goods, goodsIndex) => {
                            return goodsIndex % 2 == 1 ? (
                                <div
                                    key={goodsIndex}
                                    className={Style.goods}
                                    onClick={() => {
                                        handleClickGoods(goods);
                                    }}
                                >
                                    {shop.shopImage ? (
                                        <div className={Style.goods_row}>
                                            <img alt=""
                                                className={Style.shop_image}
                                                src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(shop.shopImage, 375)}
                                            />
                                            <div className={Style.goods_shop}>{shop.shopName}</div>
                                        </div>
                                    ) : null}
                                    <img alt=""
                                        className={Style.goods_image}
                                        src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(goods.goodsImage, 375)}
                                    />
                                    <div className={Style.goods_title}>{goods.goodsSkuName}</div>
                                    {goods.marketPriceIsHidden === true ? null : (<div className={Style.goods_market_price}>¥{goods.goodsSkuMarketPrice}</div>)}
                                    {goods.activityIsPoints?(<div className={Style.goods_sale_price}>{goods.goodsSkuPoints}积点</div>):(<div className={Style.goods_sale_price}>¥{goods.goodsSkuSalePrice}</div>)}
                                </div>
                            ) : null;
                        })}
                    </div>
                </div>
                <InfiniteScroll hasMore={false} />
                <div className={Style.empty}></div>
            </ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

ShopDetail.propTypes = {};

ShopDetail.defaultProps = {};

export default withRouter(ShopDetail);
