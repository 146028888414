import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import moment from "moment";

import Style from "./Index.module.css";

const Calendar = (props) => {
    const [array, setArray] = useState([]);
    const [value, setValue] = useState("");

    useEffect(() => {
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        let week = ["一", "二", "三", "四", "五", "六", "日"];
        let startDay = moment(props.startDay);
        let endDay = moment(props.endDay);
        let betweenDay = endDay.diff(startDay, "day");
        let betweenDayCount = 0;
        let dayCount = 0;
        let array = [];
        while (betweenDayCount < betweenDay) {
            let childrenList = [];
            startDay = startDay.add(dayCount, "days");
            let startMonth = startDay.format("YYYY年MM月");
            let weekOfDate = Number(startDay.format("E"));
            dayCount = 0;

            for (let i = 0; i < week.length; i++) {
                let day = moment(startDay.format("YYYY-MM-DD")).subtract(weekOfDate - i - 1, "d");
                let month = day.format("YYYY年MM月");

                if (startMonth == month) {
                    if (betweenDayCount < betweenDay) {
                        if (day.isSameOrAfter(startDay)) {
                            let date = day.format("YYYY-MM-DD");
                            childrenList.push({
                                date: date,
                                day: day.format("DD"),
                                week: week[i],
                                month: month,
                                disable: props.calendarList.indexOf(date) > -1,
                                active: false,
                            });

                            dayCount++;

                            betweenDayCount++;
                        } else {
                            childrenList.push({
                                date: "",
                                day: "",
                                week: week[i],
                                month: month,
                                disable: true,
                                active: false,
                            });
                        }
                    } else {
                        childrenList.push({
                            date: "",
                            day: "",
                            week: week[i],
                            month: month,
                            disable: true,
                            active: false,
                        });
                    }
                } else {
                    childrenList.push({
                        date: "",
                        day: "",
                        week: week[i],
                        month: month,
                        disable: true,
                        active: false,
                    });
                }
            }

            let isExit = false;
            for (let i = 0; i < array.length; i++) {
                if (array[i].month == startMonth) {
                    isExit = true;

                    array[i].childrenList = array[i].childrenList.concat(childrenList);

                    break;
                }
            }
            if (!isExit) {
                array.push({
                    month: startMonth,
                    childrenList: childrenList,
                });
            }
        }

        for (let i = 0; i < array.length; i++) {
            for (let j = 0; j < array[i].childrenList.length; j++) {
                if (array[i].childrenList[j].disable) {
                    array[i].childrenList[j].active = false;
                } else {
                    if (array[i].childrenList[j].date == props.value) {
                        array[i].childrenList[j].active = true;
                    } else {
                        array[i].childrenList[j].active = false;
                    }
                }
            }
        }

        setArray(array);

        //eslint-disable-next-line
    }, [props.value]);

    const hancleClickDay = (itemIndex, childrenIndex) => {
        for (let i = 0; i < array.length; i++) {
            for (let j = 0; j < array[i].childrenList.length; j++) {
                if (i == itemIndex && j == childrenIndex) {
                    array[i].childrenList[j].active = true;

                    setValue(array[i].childrenList[j].date);
                } else {
                    array[i].childrenList[j].active = false;
                }
            }
        }
        setArray([...array]);
    };

    return (
        <React.Fragment>
            <div className="adm-picker-header">
                <span
                    className={"adm-picker-header-button " + Style.bottom}
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    取消
                </span>
                <div className="adm-picker-header-title">{props.title}</div>
                <span
                    className={"adm-picker-header-button " + Style.bottom}
                    onClick={() => {
                        props.onConfirm(value);
                    }}
                >
                    确定
                </span>
            </div>
            <div className={Style.empty}></div>
            <div className={Style.row}>
                <div className={Style.col}>周一</div>
                <div className={Style.col}>周二</div>
                <div className={Style.col}>周三</div>
                <div className={Style.col}>周四</div>
                <div className={Style.col}>周五</div>
                <div className={Style.col}>周六</div>
                <div className={Style.col}>周日</div>
            </div>
            {array.map((item, itemIndex) => {
                return (
                    <React.Fragment key={itemIndex}>
                        <div className={Style.row_month}>
                            <div className={Style.month}>{item.month}</div>
                        </div>
                        <div className={Style.row}>
                            {item.childrenList.map((children, childrenIndex) => {
                                return (
                                    <div key={childrenIndex} className={Style.col}>
                                        <span
                                            className={
                                                Style.day +
                                                " " +
                                                (children.disable ? Style.day_disable : "") +
                                                " " +
                                                (children.active ? Style.day_active : "")
                                            }
                                            onClick={() => {
                                                if (!children.disable) {
                                                    hancleClickDay(itemIndex, childrenIndex);
                                                }
                                            }}
                                        >
                                            {children.day}
                                        </span>
                                        {children.disable && children.day ? <span className={Style.day_close}>关闭</span> : null}
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                );
            })}
            <div className={Style.empty}></div>
            <div className={Style.empty}></div>
        </React.Fragment>
    );
};

Calendar.propTypes = {
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    startDay: PropTypes.number.isRequired,
    endDay: PropTypes.number.isRequired,
    calendarList: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

Calendar.defaultProps = {};

export default withRouter(Calendar);
