
// 判断是否是微信访问
import ChuangShiOS from "chuangshi-os-mobile-component";

// 是否是微信客户端
export function isWechat() {
    return window.navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1
}

// 微信支付，调用之前先绑定 window.wechatPaySuccess 函数，用于支付成功的回调
export function wechatPay(paySign) {
    if (typeof window.WeixinJSBridge == "undefined" || typeof window.WeixinJSBridge.invoke == 'undefined') {
        if (document.addEventListener) {
            document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
        } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
    } else {
        onBridgeReady(paySign);
    }
}

export function onBridgeReady(paySign) {
    window.WeixinJSBridge.invoke('getBrandWCPayRequest', paySign,
        function (res) {
            console.log(res)
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                ChuangShiOS.Util.toastSuccess({
                    message: "支付成功",
                    complete: () => {
                    },
                });
                // 支付成功调用函数
                window.wechatPaySuccess && window.wechatPaySuccess();
            } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                console.log("取消支付");
            } else {
                ChuangShiOS.Util.toastError({
                    message: "支付失败 "+res.errMsg,
                    complete: () => {
                    },
                });
            }
        });
}
