import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";

import ProfileIndex from "./Index";

const data = [
    <Route
        exact
        key="ProfileIndex"
        path="/profile/index/:activityId"
        render={(props) => {
            return (
                <KeepAlive name={"/profile/index/" + props.match.params.activityId} key={props.match.params.activityId}>
                    <ProfileIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
];

export default data;
