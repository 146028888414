import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import {NavBar} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Style from "./Notice.module.css";

const ActivityNotice = (props) => {
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [activityNotice, setActivityNotice] = useState({});

    useEffect(() => {
        ChuangShiOS.Notification.emit("notice_find", {activityNoticeId: props.match.params.activityNoticeId});

        handleLoadNotice();

        //eslint-disable-next-line
    }, []);

    useActivate(() => {});

    useUnactivate(() => {});

    const handleLoadNotice = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/notice/consumer/mobile/v1/find",
            data: {
                activityNoticeId: props.match.params.activityNoticeId,
            },
            success: (response) => {
                setActivityNotice(response.data);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                【{activityNotice.activityNoticeType}】{activityNotice.activityNoticeTitle}
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} backgroundColor="#ffffff">
                <div className={Style.content} dangerouslySetInnerHTML={{__html: activityNotice.activityNoticeContent}}></div>
            </ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

ActivityNotice.propTypes = {};

ActivityNotice.defaultProps = {};

export default withRouter(ActivityNotice);
