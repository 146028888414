import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";

import MyIndex from "./Index";

const data = [
    <Route
        exact
        key="MyActivityIndex"
        path="/my/index/:activityId"
        render={(props) => {
            return (
                <KeepAlive name={"/my/index/" + props.match.params.activityId} key={props.match.params.activityId}>
                    <MyIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="MyIndex"
        path="/my/index"
        render={(props) => {
            return (
                <KeepAlive name={"/my/index"}>
                    <MyIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
];

export default data;
