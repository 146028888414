import React from "react";
import {Route} from "react-router-dom";

import AuthIndex from "./Index";

const data = [
    <Route
        exact
        key="AuthIndex"
        path="/auth/index"
        render={(props) => {
            return <AuthIndex {...props} />;
        }}
    />,
];

export default data;
