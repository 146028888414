// Application-test.js

const data = {
    host: "https://testapi.yourright.com.cn",
    //baseHost: "https://api.yourright.com.cn",
    imageUploadHost: "https://api.yourright.com.cn",
    imagePreviewHost: "https://static.yourright.com.cn",
    appId: "1305198331571605504",
    version: "1.0.0",
    platform: "WinDeal-H5",
    homePath: "/benefit/index",
    loginPath: "/login/index",
    registerPath: "/register/index",
    tokenKey: "consumer_mobile_token_",
};

export default data;
