import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {NavBar, List} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Footer from "../../component/footer/Index";

import Style from "./Index.module.css";

import MemberImage from "./image/memberImage.png";

const MyIndex = (props) => {
    const store = useSelector((store) => store);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        let token = ChuangShiOS.Util.getToken();
        if (token) {
            ChuangShiOS.Notification.emit("member_find", {});
        } else {
            setIsLaunch(true);
        }

        ChuangShiOS.Notification.on("member_find_succes", this, (data) => {
            setIsLaunch(true);
            setIsLogin(true);
        });

        return () => {
            ChuangShiOS.Notification.remove("member_find_succes", this);
        };

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        let token = ChuangShiOS.Util.getToken();
        if (token) {
            ChuangShiOS.Notification.emit("member_find", {});
        } else {
            setIsLogin(false);
        }
    });

    useUnactivate(() => {});

    return (
        <ChuangShiOS.Page>
            <NavBar back={null}>我的</NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                <List>
                    <List.Item
                        clickable
                        arrow
                        prefix={<img alt="" src={MemberImage} className={Style.profile_image} />}
                        onClick={() => {
                            if (isLogin) {
                                ChuangShiOS.Util.navigateTo(props, {
                                    url: "/profile/index/" + props.match.params.activityId,
                                });
                            } else {
                                ChuangShiOS.Util.navigateTo(props, {
                                    url: "/login/index",
                                });
                            }
                        }}
                    >
                        {isLogin ? (
                            <React.Fragment>
                                <div className={Style.profile_title}>{store.main.member.memberPhone}</div>
                                <div>
                                    <span className={Style.profile_level}>普通会员</span>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className={Style.login_title}>登录/注册</div>
                                <div className={Style.login_tips}>登录后更多权益</div>
                            </React.Fragment>
                        )}
                    </List.Item>
                    {/* <List.Item clickable arrow>
                        我的权益
                    </List.Item>
                    <List.Item clickable arrow>
                        我的订单
                    </List.Item> */}
                </List>
                <div className={Style.empty}></div>
            </ChuangShiOS.Content>
            <Footer index={2} activityId={props.match.params.activityId} orderTabBarIsHidden={props.location.query&&props.location.query.orderTabBarIsHidden}></Footer>
        </ChuangShiOS.Page>
    );
};

MyIndex.propTypes = {};

MyIndex.defaultProps = {};

export default withRouter(MyIndex);
