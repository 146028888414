import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {NavBar, List, Button} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Style from "./Index.module.css";

const MyIndex = (props) => {
    const store = useSelector((store) => store);
    const [isLaunch, setIsLaunch] = useState(false);

    useEffect(() => {
        setIsLaunch(true);

        //eslint-disable-next-line
    }, []);

    useActivate(() => {});

    useUnactivate(() => {});

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                我的账号
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                <div className={Style.empty}></div>
                <List>
                    <List.Item extra={store.main.member.memberPhone}>手机号码</List.Item>
                    {/* <List.Item clickable arrow>
                        修改密码
                    </List.Item> */}
                </List>
                {/* <Cell hasArrow title="手机号码" description={store.main.member.memberPhone} onClick={() => {}} />
                <Cell hasArrow title="修改密码" description="" onClick={() => {}} /> */}
                <div className={Style.button}>
                    <Button
                        block
                        color="danger"
                        fill="solid"
                        onClick={() => {
                            ChuangShiOS.Util.removeToken();
                            ChuangShiOS.Util.navigateBack(props);
                            //退出时跳转登录页面
                            //ChuangShiOS.Util.redirectTo(props, { url: "/login/index" });
                        }}
                    >
                        退出登录
                    </Button>
                </div>
                <div className={Style.empty}></div>
            </ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

MyIndex.propTypes = {};

MyIndex.defaultProps = {};

export default withRouter(MyIndex);
