import React from "react";
import {Route} from "react-router-dom";

import LoginIndex from "./Index";

const data = [
    <Route
        exact
        key="LoginIndex"
        path="/login/index"
        render={(props) => {
            return <LoginIndex {...props} />;
        }}
    />,
];

export default data;
