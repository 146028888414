import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";

import GoodsIndex from "./Index";

const data = [
    <Route
        exact
        key="GoodsActivityIndex"
        path="/goods/activity/index/:activityId/:activityGoodsSkuId"
        render={(props) => {
            return (
                <KeepAlive
                    name={"/goods/activity/index/" + props.match.params.activityId + "/" + props.match.params.activityGoodsSkuId}
                    key={props.match.params.activityId + "/" + props.match.params.activityGoodsSkuId}
                >
                    <GoodsIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="GoodsBenefitIndex"
        path="/goods/benefit/index/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId"
        render={(props) => {
            return (
                <KeepAlive
                    name={
                        "/goods/benefit/index/" +
                        props.match.params.memberBenefitId +
                        "/" +
                        props.match.params.benefitCategoryId +
                        "/" +
                        props.match.params.benefitGoodsSkuId
                    }
                    key={props.match.params.memberBenefitId + "/" + props.match.params.benefitCategoryId + "/" + props.match.params.benefitGoodsSkuId}
                >
                    <GoodsIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
];

export default data;
