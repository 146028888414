import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate, useAliveController} from "react-activation";
import {NavBar, Swiper,Space} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Footer from "../../component/footer/Index";

import Style from "./Index.module.css";
import {useDispatch} from "react-redux";

const ActivityIndex = (props) => {
    const dispatch = useDispatch();
    const {drop} = useAliveController();
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isError, setIsError] = useState(false);
    const [activity, setActivity] = useState({
        activityBannerList: [],
        activityNoticeList: [],
        activityCategoryList: [],
    });
    const [backArrow,setBackArrow] = useState(false); // 是否显示左上角返回箭头

    useEffect(() => {
        drop(new RegExp("/shop/activity/index/*"));

        handleLoadActivity();

        // 光大私行短链自动核销券码
        let couponCode = ChuangShiOS.Util.getParameter("couponCode");
        if (couponCode){
            localStorage.setItem("couponCode",couponCode)
        }

        let cupdDatas = ChuangShiOS.Util.getParameter("cupdDatas");
        if (cupdDatas) {
            handleHBSCLogin(cupdDatas);
        }

        let data = ChuangShiOS.Util.getParameter("data");
        let sign = ChuangShiOS.Util.getParameter("sign");
        let commCode = ChuangShiOS.Util.getParameter("commCode");
        let companyCode = ChuangShiOS.Util.getParameter("companyCode");
        if (companyCode) {
            handleCEBLogin(data, sign, commCode, companyCode);
        }

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        drop(new RegExp("/shop/activity/index/*"));
    });

    useUnactivate(() => {});

    const handleLoadMember = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/consumer/mobile/v1/find",
            data: {},
            success: (response) => {
                dispatch({
                    type: "main",
                    data: {
                        member: response.data,
                    },
                });
            },
            error: (response) => {},
            complete: (response) => {},
        });
    };

    const handleHBSCLogin = (cupdDatas) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/consumer/mobile/v1/hsbc/login",
            data: {
                cupdDatas: decodeURI(cupdDatas),
            },
            success: (response) => {
                ChuangShiOS.Util.setToken(response.data.token);
            },
            error: (response) => {},
            complete: (response) => {},
        });
    };

    const handleCEBLogin = (data, cebSign, commCode, companyCode) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/consumer/mobile/v1/ceb/login",
            data: {
                data: data,
                cebSign: cebSign,
                commCode: commCode,
                companyCode: companyCode,
            },
            success: (response) => {
                ChuangShiOS.Util.setToken(response.data.token);
                handleLoadMember();
            },
            error: (response) => {},
            complete: (response) => {},
        });
    };

    // 返回 true=是收集访问 false=非手机访问
    function isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    const documentTitle = (enterpriseCode,title)=>{
        if (enterpriseCode==='EFUND'){
            localStorage.setItem("page_title_"+enterpriseCode,title)
            document.title = title
            if (isMobile()) {
                setBackArrow(true)
            }
        }else {
            document.title = '我德权益' // 默认标题
        }
    }

    const handleLoadActivity = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/consumer/mobile/v1/find",
            data: {
                activityId: props.match.params.activityId,
            },
            success: (response) => {
                setActivity(response.data);
                // 设置浏览器标题为活动名
                documentTitle(response.data.enterpriseCode,response.data.activityName)
            },
            error: (response) => {
                // ChuangShiOS.Util.toastError({
                //     message: response.message,
                //     complete: () => {},
                // });

                setIsError(true);
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleClickBanner = (url) => {
        if (url) {
            ChuangShiOS.Util.navigateTo(props, {
                url: url,
            });
        }
    };

    const handleClickNotice = (noticeId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/activity/notice/" + props.match.params.activityId + "/" + noticeId,
        });
    };

    const handleClickShop = (activityCategoryId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/shop/activity/index/" + props.match.params.activityId + "/" + activityCategoryId,
        });
    };

    return (
        <ChuangShiOS.Page>
            <NavBar
                back={''}
                backArrow={backArrow}
                onBack={()=>{ ChuangShiOS.Util.navigateBack(props); }}
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
            >
                {activity.activityName ? activity.activityName : "活动"}
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} isError={isError} backgroundColor="#82d2d2">
                <div className={Style.empty}></div>
                <div className={Style.title}>最新推荐</div>
                <div className={Style.banner}>
                    {activity.activityBannerList.length > 0 ? (
                        <Swiper autoplay loop>
                            {activity.activityBannerList.map((activityBanner, activityBannerIndex) => {
                                return (
                                    <Swiper.Item key={activityBannerIndex}>
                                        <div
                                            key={activityBannerIndex}
                                            className={Style.banner_image}
                                            style={{
                                                backgroundImage:
                                                    "url(" +
                                                    Application.imagePreviewHost +
                                                    ChuangShiOS.Util.scaleImage(activityBanner.activityBannerImage, 750) +
                                                    ")",
                                            }}
                                            onClick={() => {
                                                handleClickBanner(activityBanner.activityBannerUrl);
                                            }}
                                        ></div>
                                    </Swiper.Item>
                                );
                            })}
                        </Swiper>
                    ) : null}
                </div>
                <div className={Style.empty}></div>
                <div className={Style.title}>最新公告</div>
                <div className={Style.notice}>
                    {activity.activityNoticeList.map((activityNotice, activityNoticeIndex) => {
                        return (
                            <div
                                key={activityNoticeIndex}
                                className={Style.notice_item}
                                onClick={() => {
                                    handleClickNotice(activityNotice.activityNoticeId);
                                }}
                            >
                                <div className={Style.notice_item_icon}></div>
                                <div className={Style.notice_item_title}>
                                    【{activityNotice.activityNoticeTimeStr}{activityNotice.activityNoticeType}】{activityNotice.activityNoticeTitle}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={Style.empty}></div>
                <div className={Style.title}>商品类型</div>
                <div className={Style.category}>
                    <Space wrap align="center">
                    {activity.activityCategoryList.map((activityCategory, activityCategoryIndex) => {
                        return (
                            <div
                                key={activityCategoryIndex}
                                className={Style.category_item}
                                onClick={() => {
                                    handleClickShop(activityCategory.activityCategoryId);
                                }}
                            >
                                <img
                                    className={Style.category_item_image}
                                    src={Application.imagePreviewHost + activityCategory.activityCategoryImage}
                                    alt={""}
                                />
                                {activityCategory.activityCategoryName}
                            </div>
                        );
                    })}
                    </Space>
                </div>
                <div className={Style.empty}></div>
            </ChuangShiOS.Content>
            {activity.activityIsHidden ? null : isLaunch ? <Footer index={0} activityId={props.match.params.activityId} orderTabBarIsHidden={activity.orderTabBarIsHidden}></Footer> : null}
        </ChuangShiOS.Page>
    );
};

ActivityIndex.propTypes = {};

ActivityIndex.defaultProps = {};

export default withRouter(ActivityIndex);
