import React from "react";
import {Route} from "react-router-dom";

import RegisterIndex from "./Index";

const data = [
    <Route
        exact
        key="RegisterIndex"
        path="/register/index"
        render={(props) => {
            return <RegisterIndex {...props} />;
        }}
    />,
];

export default data;
